<template>
  <div class="entry-packs-tree">
    <context-popup
      ref="contextPopup"
      class="catalog-tree__context-wrapper"
    >
      <slot name="contextBody">
        <catalog-tree-node-actions
          v-if="lastContextNode"
          :node="lastContextNode"
          @onDelete="onNodeDelete"
          @onAdd="onNodeAdd"
          @onEdit="onNodeEdit"
          @clear="closeContext"
        />
      </slot>
    </context-popup>

    <div class="entry-packs-tree__search px-1 mb-10">
      <m-search-input-lazy
        :list="list"
        option-label="name"
        :m-fixed-height="false"
        @onItemClick="onSearchItemClick"
      />
    </div>

    <reusable-lazy-tree
      ref="lazyTree"
      :tree="tree"
      :use-root-node="{ title: t('entryPacks.title') }"
      :draggable="editable"
      :allow-drag="allowDrag"
      :allow-drop="allowDrop"
      :node-drop="nodeDrop"
      @onNodeClick="onNodeClick"
      @setTreeRef="treeRef = $event"
    >
      <template #default="{ data, node }">
        <entry-packs-tree-node
          :data="data"
          :node="node"
          :editable="editable && canManage"
          @onContextClick="onContextNodeClick({ data, event: $event })"
        />
      </template>
    </reusable-lazy-tree>
  </div>
</template>

<script>
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import { EntryPacksTree } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPacksTree'
import EntryPacksTreeNode from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksTree/EntryPacksTreeNode.vue'
import ContextPopup from '@/vue_present/Reuse/ContextPopup/ContextPopup.vue'
import CatalogTreeNodeActions from '@/vue_present/Reuse/CatalogTree/CatalogTreeNodeActions.vue'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { LazyTreeNode } from '@/vue_present/Reuse/LazyTree/store/LazyTreeNode'
import { extractCategoryId } from '@/vue_present/Reuse/LazyTree/const'
import { extractPresenter } from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksTree/extractPresenter'
import { extractItemId } from '@/vue_apps/catalogs_root/EntryPacks/const/extractItemId'
import { defaultStringSorting } from '@/helpers/lambda'
import MSearchInputLazy from '@/vue_present/_base/MSearchInputLazy/MSearchInputLazy.vue'
import { MListService } from '@/_api/_requests/MListService'
import { EntryPackPresenter } from '@/vue_apps/catalogs_root/EntryPacks/api/EntryPackPresenter'

export default {
  name: 'EntryPacksTree',
  components: {
    MSearchInputLazy,
    CatalogTreeNodeActions,
    ContextPopup,
    EntryPacksTreeNode,
    ReusableLazyTree,
  },

  props: {
    currentNodeKey: { type: [String, Number], default: undefined },
    editable: Boolean,
    forCurrentClinic: Boolean,
  },

  emits: [
    'onNodeClick', /* id */
    'onNodeItemClick', /* {id: number, title: string} */
    'onNodeDelete',
  ],

  data () {
    return {
      tree: new EntryPacksTree({ forCurrentClinic: this.forCurrentClinic }),
      list: new MListService(
        { forCurrentClinic: this.forCurrentClinic, currentClinicId: gon.application.current_clinic.id },
        new EntryPackPresenter()
      ),
      treeRef: null,
      lastContextNode: null,
    }
  },

  computed: {
    canManage () {
      return this.$security.canManageEntryPacksPack
    },
  },

  methods: {
    emitNodeClick (item) {
      this.$emit('onNodeClick', extractItemId(item.id))
      this.$emit('onNodeItemClick', item)
    },

    /**
     * @param {LazyTreeNode} node
     */
    onNodeClick (node) {
      if (node.children) { return }

      this.emitNodeClick(node)
    },

    /** @param {{ id: number, name: string }} item */
    onSearchItemClick (item) {
      this.emitNodeClick(item)
    },

    /**
     * @param {LazyTreeNode} data
     * @param {PointerEvent} event
     */
    onContextNodeClick ({ data, event }) {
      const { clientX, clientY } = event
      this.$refs.contextPopup.open(clientX, clientY)
      this.lastContextNode = data
    },

    closeContext () {
      this.$refs.contextPopup.close()
    },

    /**
     * @param {LazyTreeNode} node
     */
    async onNodeDelete (node) {
      const canDelete = await this.__checkNodeDeletable(node)
      if (!canDelete) { return Utils.reportError('EntryPacksTree:onNodeDelete', t('category_is_not_empty'))() }

      const presenter = extractPresenter(node.isLeaf)
      const response = await presenter.destroy(node.id, {
        toJson: true,
        data: { category: { category_type: GLOBAL_CATEGORY_KINDS.ENTRY_PACKS } },
      })

      if (response?.errors) { return }

      this.treeRef.remove(node)

      this.closeContext()
      this.$emit('onNodeDelete', node.id)
    },

    /**
     * @param {LazyTreeNode} node
     */
    async __checkNodeDeletable (node) {
      if (node.isLeaf) { return true }
      const result = await new EntryPackPresenter().fetchNode(node.id)

      return !(result.categories?.length || result.items?.length)
    },

    /**
     * @param {LazyTreeNode} parentNode - куда добавляется
     * @param {string} name - название
     * @param {boolean} isCategory - категория или айтем
     * @return {Promise<void>}
     */
    async onNodeAdd ({ parentNode, name, isCategory }) {
      const presenter = extractPresenter(!isCategory)
      const categoryId = extractCategoryId(parentNode.id)
      const data = isCategory
        ? { category: { title: name, parentId: categoryId, categoryType: GLOBAL_CATEGORY_KINDS.ENTRY_PACKS } }
        : { name, categoryId }

      const response = await presenter.create(data, { toJson: true })
      if (response.errors) { return }

      const newNodeData = {
        id: response.id,
        title: response.name || response.title,
        children: (isCategory && []) || undefined,
      }

      const lazyTreeNode = new LazyTreeNode(
        this.tree,
        newNodeData,
        !isCategory
      )

      this.__addNodeToTree(lazyTreeNode, parentNode)
    },

    /**
     * @param {LazyTreeNode} node
     * @param {LazyTreeNode} parentNode
     * @return {*}
     * @private
     */
    __addNodeToTree (node, parentNode) {
      this.tree.treeMap[node.id] = node

      this.treeRef.append(node, parentNode)

      this.__sortTreeNode(node, parentNode)
    },

    /**
     * @param {LazyTreeNode} node
     * @param {LazyTreeNode} parentNode
     * @return {void}
     * @private
     */
    __sortTreeNode (node, parentNode) {
      const treeParentNode = this.treeRef.store.nodesMap[parentNode.id]
      if (!treeParentNode) { return }

      const childNodes = (treeParentNode.childNodes) || []

      const childCatalogs = childNodes
        .filter((node) => !node.data.isLeaf)
        .sort((a, b) => defaultStringSorting(a.data.title, b.data.title))

      const childItems = childNodes
        .filter((node) => node.data.isLeaf)
        .sort((a, b) => defaultStringSorting(a.data.title, b.data.title))

      treeParentNode.childNodes = [
        ...childCatalogs,
        ...childItems,
      ]
    },

    /**
     * @param {LazyTreeNode} node
     * @param {String} name
     * @return {Promise<void>}
     */
    async onNodeEdit ({ node, name }) {
      const presenter = extractPresenter(node.isLeaf)
      const id = node.id
      const data = node.isLeaf
        ? { id, name }
        : { id, category: { title: name, categoryType: GLOBAL_CATEGORY_KINDS.ENTRY_PACKS } }

      const response = await presenter.update(data, { toJson: true })
      if (response.errors) { return }

      node.title = name
      this.closeContext()
    },

    /**
     * @param {ElTreeNode} draggingNode
     * @param {ElTreeNode} dropNode
     * @param {'inner'|'prev'|'next'} type
     */
    allowDrop (draggingNode, dropNode, type) {
      return !dropNode.data?.isLeaf &&
          type === 'inner' &&
          draggingNode.id !== dropNode.id &&
          draggingNode.parent.id !== dropNode.id
    },

    /**
     * @param {ElTreeNode} draggingNode
     * @return {boolean}
     */
    allowDrag (draggingNode) {
      return Boolean(draggingNode.data.id)
    },

    /**
     * Завершение перетаскивания
     *
     * @param {ElTreeNode} draggingNode
     * @param {ElTreeNode} dropNode
     * @param {'inner'|'prev'|'next'} dropType
     * @param {DragEvent} dragEvent
     * @return {Promise<void>}
     */
    async nodeDrop (draggingNode, dropNode, dropType, dragEvent) {
      /** @type {LazyTreeNode} */
      const draggingData = draggingNode.data
      const categoryId = extractCategoryId(dropNode.data.id)
      const presenter = extractPresenter(draggingData.isLeaf)
      const data = draggingData.isLeaf
        ? { id: draggingData.id, name: draggingData.title, categoryId }
        : { id: draggingData.id, parentId: categoryId, categoryType: GLOBAL_CATEGORY_KINDS.ENTRY_PACKS }

      await presenter.update(data, { toJson: true })
    },

    /**
     * @public
     *
     * @param {number} id
     * @param {string} name
     */
    updateNodeTitle ({ id, name }) {
      if (!this.tree.treeMap[id]) { return }
      this.tree.treeMap[id].title = name
    },

    /**
     * @public
     *
     * @param {number} id
     */
    removeNode (id) {
      if (!this.tree.treeMap[id]) { return }
      this.treeRef.remove(this.tree.treeMap[id])
    },
  },
}
</script>
