import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_SEARCH_VALUE,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
} from '@/vue_components/store/modules/filters_base'
import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { cloneDeep, omit } from 'lodash'
import { IDataItem, IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'

export class MListServiceModel<DataItem, FiltersMap> {
  static getDefaultList () {
    return {
      data: [],
      totalItems: DEFAULT_TOTAL_ITEMS,
      totalPages: DEFAULT_TOTAL_PAGES,
    }
  }

  protected _data: Array<IDataItem<DataItem>> = [] as Array<IDataItem<DataItem>>

  protected _totals: IDataItem<DataItem> = null

  protected _filters: FiltersMap = {} as FiltersMap

  protected _searchQuery: string = DEFAULT_SEARCH_VALUE

  protected _totalPages: number = DEFAULT_TOTAL_PAGES

  protected _totalItems: number = DEFAULT_TOTAL_ITEMS

  protected filtered: boolean = false

  protected offset: number = DEFAULT_OFFSET

  protected limit: number = DEFAULT_LIMIT

  protected initialFilters: FiltersMap = {} as FiltersMap

  protected _cache: MLocalStorage = null

  protected cachedData = {}

  protected DataItemEntity!: IDataItem<DataItem>

  protected TotalsItemEntity: IDataItem<DataItem> = null

  constructor (config: IMListServiceApiConfig<DataItem, FiltersMap>) {
    this.checkInputs(config)

    this.initialFilters = cloneDeep(config.defaultFilters)
    this.DataItemEntity = config.DataItemEntity

    this.fillOptional(config)
    this.restoreFilters()
  }

  protected initFilters (filters: FiltersMap) {
    this._filters = { ...filters } || {} as FiltersMap
  }

  protected setFiltered (filtered: boolean = true) {
    this.filtered = filtered
  }

  private checkInputs (config: IMListServiceApiConfig<DataItem, FiltersMap>) {
    if (!config) {
      throw new Error('Не указана конфигурация')
    }

    if (!config?.defaultFilters) {
      throw new Error('Не указаны defaultFilters')
    }

    if (config?.src === undefined) {
      throw new Error('Не указан src')
    }

    if (!config?.DataItemEntity) {
      throw new Error('Не указан DataItemEntity')
    }
  }

  private fillOptional (config: IMListServiceApiConfig<DataItem, FiltersMap>) {
    if (config?.limit) {
      this.limit = config.limit
    }

    this._cache = new MLocalStorage(config.cacheKey)
    this.cachedData = this._cache.restore() || {}

    if (config?.TotalsItemEntity) {
      this.TotalsItemEntity = config.TotalsItemEntity
    }
  }

  private restoreFilters () {
    const cachedFilters = omit(this.cachedData, ['searchQuery', 'offset', 'limit'])
    this.setFiltered(Object.keys(cachedFilters).length > 0)

    this.initFilters({ ...this.initialFilters, ...cachedFilters })
  }
}
