<template>
  <m-modal
    class="entry-pack-add-confirm-modal"
    :visible="visible"
    modal-title-icon="pack"
    :dialog-title="t('entryPacks.addedEntryTypes')"
    width="900px"
    @update:visible="$emit('cancel')"
  >
    <div class="entry-pack-entry-type-wrapper">
      <div class="entry-pack-entry-types">
        <slot name="entryPack" />

        <m-divider class="mb-0" />

        <entry-pack-entry-type-row
          v-for="entryType in entryTypes"
          :key="`entryPackEntryType:${entryType.id}`"
          :entry-type="entryType"
          :disabled="disabled"
          @onAmountChange="entryType.setAmount($event)"
          @remove="entryType.remove()"
          @restore="entryType.restore()"
        />
      </div>

      <div class="bold mt-indent">
        {{ totalText }}
      </div>
    </div>

    <template #footer-left>
      <div class="flex align-center h-100 gap-indent">
        <m-button
          template="add"
          :text="t('add')"
          @click="onConfirm"
        />

        <span class="prompt-notice">
          {{ t('entryPacks.infoWarning') }}
        </span>
      </div>
    </template>
  </m-modal>
</template>

<script lang="ts">
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import EntryPackEntryTypeRow
  from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksModal/EntryPackEntryTypeRow.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MDivider from '@/vue_present/_base/MDivider/MDivider.vue'
import { PropType } from 'vue'
import { EntryPackEntryType } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntryType'

export default {
  name: 'EntryPackAddConfirmModal',
  components: {
    MDivider,
    MButton,
    EntryPackEntryTypeRow,
    MModal,
  },

  props: {
    entryTypes: {
      type: Array as PropType<EntryPackEntryType[]>,
      default: () => [],
      required: true,
    },

    visible: Boolean,
    disabled: Boolean,
  },

  computed: {
    validEntryTypes () {
      return this.entryTypes.filter(({ removed }) => !removed)
    },

    totalAmount () {
      return this.validEntryTypes
        .reduce((acc, { amount }) => acc + amount, 0)
    },

    totalSum () {
      return this.$filters.currency(
        this.validEntryTypes
          .reduce((acc, { price, amount }) => acc + parseFloat(price) * amount, 0)
      )
    },

    totalText () {
      return t(
        'entryPacks.totalsTemplate',
        { totalAmount: this.totalAmount, totalSum: this.totalSum }
      )
    },
  },

  methods: {
    onConfirm () {
      this.$emit('confirm', this.validEntryTypes)
    },
  },
}
</script>
