function getCheckbox (h, data) {
  const that = this

  return h(
    'el-checkbox',
    {
      class: 'mr-5',
      style: 'top: -2px',
      props: {
        value: Boolean(data.rate),
        disabled: data.loading,
      },
      nativeOn: {
        click (e) {
          e.stopPropagation()
          e.preventDefault()
          if (data.loading) { return }
          that.onServiceItemCBClick(data)
        },
      },
    }
  )
}

function getRateTag (h, data) {
  const that = this
  const on = {
    click (e) {
      e.stopPropagation()
      e.preventDefault()
      that.onRateClick({ e, data })
    },
  }

  const directives = [
    {
      name: 'loading',
      value: data.loading,
    },
  ]

  if (data.rate === null || data.rate === undefined) {
    return h(
      'el-tag', {
        class: 'el-tag--plain mr-5 text-center',
        style: 'min-width: 70px; border-color: #dcdfe6',
        on,
        directives,
      },
      [
        h('i', { class: 'fal fa-plus grey-dark' }),
      ]
    )
  }

  const realRateType = data.rateType === 'percent' ? '%' : gon.localization.currency.symbol_character

  return h(
    'el-tag', {
      class: 'el-tag--plain mr-5 text-center',
      style: 'min-width: 70px',
      on,
      directives,
    },
    `${data.rate} ${realRateType}`
  )
}

export function servicesRatesTreeRenderContent (h, { node, data }) {
  const __class = data.childNodes
    ? node.expanded
      ? 'fa-folder-open warning'
      : 'fa-folder warning'
    : data.icon

  const rateTag = getRateTag.call(this, h, data)
  const checkbox = getCheckbox.call(this, h, data)

  return h(
    'span',
    { class: 'custom-service-tree-node' },
    [
      checkbox,
      rateTag,
      h('i', { class: 'fad fa-fw mr-5 ' + __class }),
      h('span', { directives: [{ name: 'tooltip', value: data.title }] }, data.title),
    ]
  )
}
