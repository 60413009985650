import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import { EntryPackPresenter } from '@/vue_apps/catalogs_root/EntryPacks/api/EntryPackPresenter'

export class EntryPacksTree extends LazyTree {
  constructor (config = {}) {
    super(new EntryPackPresenter())
    this.forCurrentClinic = Boolean(config.forCurrentClinic)
  }

  fetchNode (id, categoryType, config = {}) {
    const customConfig = this.forCurrentClinic
      ? { data: { forCurrentClinic: true, currentClinicId: gon.application.current_clinic.id } }
      : {}

    return super.fetchNode(id, categoryType, {
      ...config,
      ...customConfig,
    })
  }
}
