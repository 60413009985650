<template>
  <si class="flex-grow-1 w-100 max-height-100">
    <slot name="control">
      <si-control
        :si-control-btns-custom-classes="['reset-margin']"
        @refresh="$emit('onRefreshData')"
        @reset="resetFilters"
      >
        <div class="flex flex-column flex-grow-1">
          <div class="flex">
            <slot name="pre-search">
              <m-button
                v-if="canManage"
                v-tooltip="addItemTooltip"
                type="success"
                :icon="primaryIcon"
                plus-icon
                class="mr-5"
                :no-use-fw="false"
                @click="$emit('onAddItem')"
              />
            </slot>

            <slot name="control-search">
              <!-- todo: под замену -->
              <search-input
                v-if="useSearch"
                :value="searchValue"
                :placeholder="searchInputPlaceholder"
                @update:value="$updateSync('searchValue', $event)"
              />
            </slot>
          </div>

          <div class="si-generator-filters flex">
            <slot name="control-filters" />
          </div>

          <slot name="control-refresh" />
          <slot name="control-reset" />
        </div>

        <template #refresh>
          <span class="empty-slot" />
        </template>

        <template #reset>
          <span class="empty-slot" />
        </template>
      </si-control>
    </slot>

    <slot name="beforeTable" />

    <slot name="table">
      <si-table
        v-if="items && items.length"
        v-loading="loading"
      >
        <template #header>
          <slot name="table-header">
            <si-field>
              <div
                v-if="useCheckboxes"
                style="max-width: 30px"
              >
                <el-checkbox
                  v-model="massSelectCheckBoxValue"
                  class="el-checkbox_big"
                  :indeterminate="massSelectIndeterminate"
                />
              </div>

              <slot
                v-for="tableField in tableFields"
                :name="`th-${tableField}`"
              >
                <div
                  :key="tableField"
                  class="col"
                  :class="[`col__${tableField}`, tableStructure.headers[tableField].css]"
                >
                  <template v-if="tableStructure.headers[tableField].icon">
                    <m-icon
                      v-tooltip="tableStructure.headers[tableField].tooltip"
                      :icon="tableStructure.headers[tableField].icon"
                      :color="tableStructure.headers[tableField].color"
                      :use-brand="tableStructure.headers[tableField].useBrand"
                    />
                  </template>
                  <template v-else>
                    <span v-tooltip="tableStructure.headers[tableField].tooltip">
                      {{ tableStructure.headers[tableField].title }}
                    </span>
                  </template>
                </div>
              </slot>

              <slot name="table-header-options">
                <div
                  v-if="!options.empty"
                  class="col col__options"
                  style="max-width: 80px"
                />
              </slot>
            </si-field>
          </slot>
        </template>

        <template #body>
          <slot name="table-body">
            <si-field
              v-for="item in items"
              :key="`itemId:${item.id}`"
              :deleted="item.deleted"
              :class="{ active: item.id === activeItemId }"
              class="pointer"
              @safe-click="!item.deleted && item.id !== activeItemId && $emit('onItemClick', item)"
              @restore="restoreItem(item)"
            >
              <div
                v-if="useCheckboxes"
                style="max-width: 30px"
              >
                <el-checkbox
                  class="el-checkbox_big"
                  :value="item.selected"
                  :disabled="!item.selectable"
                  @change="item.selected = $event; $emit('onSetItemSelected', item)"
                />
              </div>

              <slot
                v-for="tableField in tableFields"
                :name="`tr-${tableField}`"
                :item="item"
              >
                <!-- Особый вариант для телефона -->
                <div
                  v-if="tableField === 'phone'"
                  :key="tableField"
                  class="col col__phone"
                  :class="tableStructure.headers.phone.css"
                >
                  <span
                    class="phone_number"
                    :data-phone="item.phone"
                    :data-client-id="item.clientId"
                  >
                    {{ item[tableField] }}
                  </span>
                </div>

                <!-- Особый вид для даты/времени -->
                <div
                  v-else-if="tableField === 'dateTime'"
                  :key="tableField"
                  class="col col__dateTime"
                  :class="tableStructure.headers.dateTime.css"
                >
                  <span :class="item.dateTime.dateCss">
                    {{ item.dateTime.formattedDate }}
                  </span>
                </div>

                <!-- Особый вид для иконки -->
                <div
                  v-else-if=" item[tableField] && typeof item[tableField] === 'object' && item[tableField].isIcon"
                  :key="tableField"
                  class="col"
                  :class="[
                    `col__${tableField}`,
                    tableStructure.headers[tableField].css,
                  ]"
                >
                  <m-icon
                    v-tooltip="item[tableField].tooltip"
                    :icon="item[tableField].value"
                    :color="item[tableField].color"
                    :use-brand="item[tableField].useBrand"
                  />
                </div>

                <!-- Особый вид для структуры -->
                <div
                  v-else-if="item[tableField] && typeof item[tableField] === 'object' && item[tableField].isStruct"
                  :key="tableField"
                  class="col"
                  :class="[
                    `col__${tableField}`,
                    tableStructure.headers[tableField].css,
                  ]"
                >
                  <span
                    v-tooltip="item[tableField].tooltip"
                    :class="item[tableField].css"
                  >
                    {{ item[tableField].value }}
                  </span>
                </div>

                <!-- Дефолт -->
                <div
                  v-else
                  :key="tableField"
                  class="col"
                  :class="[
                    `col__${tableField}`,
                    tableStructure.headers[tableField].css,
                  ]"
                >
                  {{ item[tableField] }}
                </div>
              </slot>

              <div
                v-if="!options.empty"
                class="col col__options"
                style="max-width: 80px"
                @click.stop="() => {}"
              >
                <i
                  v-if="options.edit"
                  class="fad fa-pencil warning font-size-17 pointer ml-5"
                  @click="$emit('onEditItemClick', item)"
                />

                <popover
                  v-if="options.delete"
                  @yes="deleteItem(item)"
                >
                  <i
                    slot="reference"
                    class="fad fa-trash danger font-size-17 pointer ml-5"
                  />
                </popover>
              </div>
            </si-field>
          </slot>
        </template>

        <template #footer>
          <slot name="table-footer" />
        </template>
      </si-table>
      <slot
        v-else
        name="not-found-result"
      >
        <not-found-result
          :filtered="isFiltered"
          @reset-filters="resetFilters"
        />
      </slot>
    </slot>

    <slot name="footer">
      <si-footer>
        <slot name="footer-pagination">
          <pagination
            v-if="totalPages > 1"
            class="mt-15 mb-0"
            :current-page="currentPage"
            :page-count="totalPages"
            layout="prev, pager, next"
            @current-change="$updateSync('currentPage', $event)"
          />
        </slot>

        <slot
          v-if="useCheckboxes"
          name="mass-actions"
        >
          <button
            type="button"
            class="btn btn-sm btn-link"
            @click="massDelete"
          >
            {{ t('delete_selected') }}
          </button>

          <button
            type="button"
            class="btn btn-sm btn-link"
            @click="massRestore"
          >
            {{ t('restore_selected') }}
          </button>
        </slot>
      </si-footer>
    </slot>
  </si>
</template>

<script>
import Si from '@/vue_components/sort_items/si'
import SiControl from '@/vue_components/sort_items/si_control'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import SiFooter from '@/vue_components/sort_items/si_footer'
import Pagination from '@/vue_components/common/pagination'
import SearchInput from '@/vue_components/common/search_input'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import Popover from '@/vue_components/common/popover/popover'
import { cloneDeep } from 'lodash'
import NotFoundResult from '@/vue_components/common/not_found_result'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'

/**
 * Генератор таблиц, пример SiGeneratedExample.vue
 * http://localhost:3000/si
 */
export default {
  name: 'SiGenerator',
  components: { MIcon, MButton, NotFoundResult, Popover, SiField, SearchInput, Pagination, SiFooter, SiTable, SiControl, Si },
  emits: [
    /**
     * SI CONTROLS REGION
     */
    'onAddItem',
    'update:searchValue',
    'onRefreshData',
    'onResetFilters',

    /**
     * TABLE REGION
     */
    'onItemClick', // param: item from items
    'onSetItemSelected', // param: item from items
    'onEditItemClick', // param: item from items
    'onDeleteItem', // param: item from items
    'onRestoreItem', // param: item from items
    'onSetSelectedFields', // param: this.selectedFields

    /**
     * PAGINATION(FOOTER) REGION
     */
    'update:currentPage',
    'onMassRestore', // param: this.selectedFields
    'onMassDelete', // param: this.selectedFields
  ],

  props: {
    /**
     * TABLE STRUCTURE REGION
     */
    /**
     * При использовании useCheckboxes требуется указать для каждого item следующие поля:
     * 1) selected - Boolean, признак (ИЗМЕНЯЕТСЯ ПО ССЫЛКЕ) того что item был отмечен
     * 2) selectable - Boolean, некоторое условие, позволяющее отмечать item
     * 3) deleted - Boolean, признак удаления item
     */
    useCheckboxes: Boolean,

    useSearch: Boolean,

    /**
     * Требуется объект следующего типа
     * {{
     *   options: {},
     *   headers: {}
     * }}
     *
     * options - объект для отображения кнопок: удалить, редактировать
     * headers - объект заголовков таблицы, названия должны совпадать полями объекта данных в Items,
     * элемент headers - String или объект с полями { title: String, css: String, tooltip: Boolean }
     */
    tableSchema: {
      type: Object,
      default: () => ({}),
    },

    primaryIcon: {
      type: String,
      default: '',
    },

    items: {
      type: Array,
      default: () => [],
    },

    activeItemId: {
      type: Number,
      default: -1,
    },

    isFiltered: Boolean,

    /**
     * SEARCH REGION
     */
    searchValue: {
      type: String,
      default: '',
    },

    searchInputPlaceholder: {
      type: String,
      default: t('sort_search'),
    },

    /**
     * PAGINATION REGION
     */
    currentPage: {
      type: Number,
      default: 1,
    },

    totalPages: {
      type: Number,
      default: 1,
    },

    addItemTooltip: {
      type: String,
      default: t('add'),
    },

    loading: Boolean,

    isSystemFn: PropsTypes.Function(() => false),

    canManage: Boolean,
  },

  data () {
    return {
      selectableCount: 0,
      massSelectCheckBoxValue: false,
    }
  },

  computed: {
    /**
     * @typedef {{
     *  title: string,
     *  tooltip?: string,
     *  icon?: string,
     *  color?: string,
     * }} TCustomTableSchemaHeader
     */

    /**
     * @return {{ options: Object, headers: Record<string, TCustomTableSchemaHeader> }}
     */
    tableStructure () {
      const tableStructure = cloneDeep(this.tableSchema)
      Object.keys(tableStructure.headers).forEach((key) => {
        if (typeof tableStructure.headers[key] !== 'string') { return }
        tableStructure.headers[key] = { title: tableStructure.headers[key] }
      })

      return tableStructure
    },

    tableFields () {
      return Object.keys(this.tableStructure.headers)
    },

    options () {
      return this.tableStructure.options || { empty: true }
    },

    selectedFields () {
      return this.useCheckboxes
        ? this.items.filter((item) => item.selected)
        : []
    },

    selectedCount () {
      return this.selectedFields.length
    },

    massSelectIndeterminate () {
      return this.selectedCount > 0 && this.selectedCount < this.selectableCount
    },
  },

  watch: {
    items () {
      this.calculateSelectableCount()
      this.$nextTick(() => {
        Services.telephony.reset()
      })
    },

    massSelectCheckBoxValue (to) {
      this.setSelectAllFields(to)
    },

    selectedFields () {
      this.$emit('onSetSelectedFields', this.selectedFields)
    },
  },

  created () {
    this.calculateSelectableCount()
  },

  methods: {
    calculateSelectableCount () {
      if (!this.useCheckboxes) { return }
      this.selectableCount = this.items.reduce((acc, item) => acc + Number(item.selectable), 0)
    },

    setSelectAllFields (value) {
      if (!this.useCheckboxes) { return }

      this.items.forEach((item) => {
        if (!item.selectable) { return }
        item.selected = value
      })
    },

    restoreItem (item) {
      this.$emit('onRestoreItem', item)
    },

    deleteItem (item) {
      this.$emit('onDeleteItem', item)
    },

    massRestore () {
      this.$emit('onMassRestore', this.selectedFields)
    },

    massDelete () {
      this.$emit('onMassDelete', this.selectedFields)
    },

    resetFilters () {
      this.$emit('onResetFilters')
    },
  },
}
</script>
