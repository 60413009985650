import { omit } from 'lodash'
import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'

/**
 * @typedef {{
 *   id: number,
 *   rate: number,
 *   rateType: "percent" | "fixed",
 *   serviceRateId: number,
 *   rawId: number,
 *   _destroy?: boolean,
 *   _forceClearDescendents?: boolean,
 * }} RawServiceRateItem
 */

export class ServiceRateItem {
  constructor (item) {
    this.id = item.id
    this.title = item.title
    this.rate = item.rate
    this.rateType = item.rateType
    this.serviceRateId = item.serviceRateId

    this.loading = false

    /** @type {ServiceRateCategory}*/
    this.parentNode = null
  }

  static getIdWithPrefix (id) {
    return id
  }

  getId () {
    return parseInt(`${this.id}`.replace(/\D/g, ''))
  }

  setRate (rate, rateType) {
    this.rate = (rate === null || rate === undefined) ? null : parseFloat(rate).toFixed(2)
    this.rateType = rateType
  }

  unsetRate () {
    this.rate = null
    this.rateType = null
  }

  unsetParentRate () {
    this.unsetRate()
    if (this.parentNode) { this.parentNode.unsetParentRate() }
  }

  setServiceRateId (serviceRateId = null) {
    this.serviceRateId = serviceRateId
  }

  setParentNode (node) {
    this.parentNode = node
  }

  unsetChildRate () {
    this.unsetRate()
  }

  unsetChildServiceRateId () {
    this.setServiceRateId()
  }

  unsetParentServiceRateId () {
    this.setServiceRateId()
    if (this.parentNode) { this.parentNode.unsetParentServiceRateId() }
  }

  hasFarParentWithServiceRateId () {
    if (!this.parentNode || this.parentNode?.getId() === 0) { return false }
    if (this.parentNode.serviceRateId) { return true }

    return this.parentNode.hasFarParentWithServiceRateId()
  }

  noRate () {
    return this.rate === null || this.rate === undefined
  }

  loadingStart () {
    this.loading = true
  }

  loadingStop () {
    this.loading = false
  }

  /**
   * Генерация данных для запроса для ЗАВИСИМОГО изменяемого ServiceRateItem
   * Зависимый - есть родительские категории с serviceRateId
   * @param {boolean} forceClearDescendents - очистка записей на потомках
   * @return {RawServiceRateItem[]}
   */
  generateQueryData (forceClearDescendents = true) {
    const formatNode = (node) => omit({ ...node, id: node.getId(), rawId: node.id }, ['parentNode', 'childNodes'])

    /**@type {RawServiceRateItem} */
    const currentNode = {
      ...formatNode(this),
      _destroy: true,
      _forceClearDescendents: forceClearDescendents || undefined,
    }

    if (!this.parentNode) { return [] }
    // случай когда ServiceRateItem в корне
    if (this.parentNode.id === ServiceRateCategory.getIdWithPrefix(0)) { return [currentNode] }
    // случай когда ServiceRateItem находится в каталоге, у которого нет родителей с serviceRateId
    if (!this.hasFarParentWithServiceRateId()) { return [currentNode] }

    const siblings = this.parentNode.childNodes
      .filter((node) => node.getId() !== this.getId())
      .map(formatNode)

    return [
      ...this.parentNode.generateQueryData(false),
      ...siblings,
      currentNode,
    ]
  }

  /**
   * Генерация данных для запроса для одного НЕЗАВИСИМОГО изменяемого ServiceRateItem
   * Независимый - нет родительских категорий с serviceRateId
   * @param {number} rate
   * @param {string} rateType
   * @return {RawServiceRateItem}
   */
  generateSingleQueryData (rate, rateType) {
    return {
      ...omit(this, ['parentNode', 'childNodes']),
      rawId: this.id,
      id: this.getId(),
      rate,
      rateType,
      _forceClearDescendents: true,
    }
  }
}
