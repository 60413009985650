import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { referralAdapter } from '@/_api/MReferral/referralAdapter'
import { referralSearchAdapter } from '@/_api/MReferral/referralSearchAdapter'
import formatter from '@/lib/formatters/formatter'

export class MReferralPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'referral',
      location: 'MReferralsPresenter',
      routes: {
        one: Routes.referral_path,
        all: Routes.referrals_path,
        list: Routes.catalogs_referrals_list_path,
      },
    })
  }

  /**
   * @param id
   * @param config
   * @returns {Promise<*>}
   */
  fetch (id, config = {}) {
    const options = {
      url: Routes.referral_fetch_path(id),
      toClientAdapter: referralAdapter.toClient,
    }

    return super.fetch(id, options)
  }

  create (data, config = {}) {
    const _config = {
      toServerAdapter: referralAdapter.toServer,
      dataType: 'json',
      contentType: 'application/json',
    }

    return super.create(data, _config)
      .then((response) => Promise.resolve({
        ...response,
        shortName: formatter.formatValue(response, ['user:shortName']),
      }))
  }

  update (data, config = {}) {
    const _config = {
      toServerAdapter: referralAdapter.toServer,
      dataType: 'json',
      contentType: 'application/json',
    }

    return super.update(data, _config)
  }

  list (data, config) {
    return super.list(data, config)
      .then((response) => Promise.resolve({
        ...response,
        data: (response.data || []).map((item) => ({
          ...item,
          shortName: formatter.formatValue(item, ['user:shortName']),
        })),
      }))
  }

  search (data, config = {}) {
    const options = {
      url: Routes.search_referrals_path(),
      data: { title: data.searchQuery },
    }

    return super.get(options).promise
      .then((data) => Promise.resolve(referralSearchAdapter.toClient(data)))
  }
}
