<template>
  <basic-tooltip
    :text="text"
    :placement="placement"
  >
    <i class="fad fa-fw fa-info-circle explanation-mark" />
  </basic-tooltip>
</template>

<script>
import BasicTooltip from '@/vue_components/common/basic_tooltip'

export default {
  name: 'ExplanationMark',
  components: { BasicTooltip },
  props: {
    text: {
      type: String,
      required: true,
    },

    placement: {
      type: String,
      default: 'top-start',
    },
  },
}
</script>
