
export const entryPacksEntryTypesAdapter = {
  /**
   * @param {IEntryPackRaw} response
   * @return {IEntryPackEntryType[]}
   */
  toClient (response) {
    /* filter*some - потенциальная квадратичная,
    но клиник мало, а также до some может даже и не дойдёт  */
    return response.entries
      .filter(
        ({ allowedClinics }) =>
          !allowedClinics.length ||
          allowedClinics.some(({ id }) => gon.application.current_clinic.id === id)
      )
      .map(({ entryType, amount }) => ({ ...entryType, amount }))
  },
}
