<template>
  <div class="entry-types-search-add flex gap-indent-small">
    <m-search-select-input
      v-if="useSearch"
      v-tooltip="!canViewEntryType && t('insufficient_access_rights')"
      class="entry-types-search-add__input"
      :items="entryTypes"
      :placeholder="t('print_or_select_from_catalog')"
      :search-query="searchQuery"
      :not-results="noResults"
      :loading="loading"
      :disabled="!canViewEntryType"
      trigger-type="click"
      use-stop-hide-on-click
      drop-down-menu-class="entry-types-search-add__menu"
      @update:searchQuery="onSearchQueryUpdate"
      @onMenuItemClick="onEntryTypeClick"
      @onDropdownVisibleChange="onDropdownVisibleChange"
    >
      <el-dropdown-item
        v-for="entryType in entryTypes"
        :key="`entryType:${entryType.id}`"
        class="entry-option"
        :command="entryType"
      >
        <span class="entry-option__number">{{ entryType.number }}</span>

        <span
          v-if="entryType.analysis_laboratory.title"
          class="entry-option__analysis-laboratory-title"
        >
          {{ entryType.analysis_laboratory.title }}
        </span>

        <span class="entry-option__title">{{ entryType.title }}</span>
        <span class="entry-option__price">{{ entryType.price }}</span>
      </el-dropdown-item>
    </m-search-select-input>

    <entry-types-tree-modal
      v-if="useEntryTypes"
      :for-current-user="forCurrentUser"
      :for-current-clinic="forCurrentClinic"
      @onNodeClick="onEntryTypeClick"
    />

    <entry-packs-modal
      v-if="useEntryPacks"
      @appendEntryTypes="onAppendEntryTypes"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MSearchSelectInput
  from '@/vue_present/_base/inputs/MSearchSelectInputDeprecated/MSearchSelectInputDeprecated.vue'
import { entryTypesEndpoint } from '@/api_entities/entry_types/entryTypesEndpoint'
import { camelToSnake } from '@/_api/_requests/helpers'
import { GLOBAL_DEBOUNCE_DEFAULT, GLOBAL_SEARCH_MIN_LENGTH } from '@/_global_scripts/GLOBAL_CONSTS'
import EntryTypesTreeModal from '@/vue_present/Reuse/EntryTypes/EntryTypesTreeModal/EntryTypesTreeModal.vue'
import { debounce } from 'lodash'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import EntryPacksModal from '@/vue_apps/catalogs_root/EntryPacks/EntryPacksModal.vue'

export default defineComponent({
  name: 'EntryTypesSearchAdd',
  components: { EntryPacksModal, EntryTypesTreeModal, MSearchSelectInput },
  mixins: [SpinnerHolder],

  props: {
    entryTypeLegacyCallback: { type: Function, default: undefined },
    entryPackLegacyCallback: { type: Function, default: undefined },
    forCurrentClinic: { type: Boolean, default: true },
    forCurrentUser: { type: Boolean, default: true },
    useEntryPacks: { type: Boolean, default: true },
    useSearch: { type: Boolean, default: true },
    useEntryTypes: { type: Boolean, default: true },
  },

  emits: [
    'onEntryTypeAdd',
    'onEntryPackAdd',
  ],

  data () {
    return {
      searchQuery: '',
      entryTypes: [],
      debouncedSearch: debounce((searchQuery) => this.search(searchQuery), GLOBAL_DEBOUNCE_DEFAULT),
    }
  },

  computed: {
    noResults () {
      return !this.entryTypes.length && !this.loading
    },

    canViewEntryType () {
      return this.$security.canViewEntryType
    },
  },

  watch: {
    searchQuery (to) {
      this.debouncedSearch(to)
    },
  },

  methods: {
    onDropdownVisibleChange (beenOpened) {
      if (!beenOpened) { this.searchQuery = '' }
    },

    onSearchQueryUpdate (newValue) {
      this.searchQuery = newValue ?? ''
    },

    search (title = '') {
      if (title.length < GLOBAL_SEARCH_MIN_LENGTH) { return }

      const data = camelToSnake({
        title,
        forCurrentUser: this.forCurrentUser || undefined,
        clinicIds: this.forCurrentClinic
          ? [gon.application.current_clinic.id]
          : (gon.application.current_user_clinics || []).map(({ id }) => id),
      })

      this.withSpinner(
        entryTypesEndpoint.find(data)
          .then((items) => { this.entryTypes = items })
      )
    },

    onEntryTypeClick (entryType) {
      if (this.entryTypeLegacyCallback) { return this.entryTypeLegacyCallback(entryType) }
      this.$emit('onEntryTypeAdd', entryType)
    },

    /**
     * @param {IEntryPackEntryType[]} entryTypes
     */
    onAppendEntryTypes (entryTypes) {
      if (this.entryPackLegacyCallback) { return this.entryPackLegacyCallback(entryTypes) }
      this.$emit('onEntryPackAdd', entryTypes)
    },
  },
})
</script>
