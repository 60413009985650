<template>
  <div class="entry-packs-modal">
    <m-modal
      modal-title-icon="pack"
      :dialog-title="t('entryPacks.title')"
      :visible.sync="modalVisible"
      width="800px"
      use-max-height
    >
      <entry-packs-tree
        class="w-100"
        for-current-clinic
        @onNodeClick="onEntryPackClick"
        @onNodeItemClick="onEntryPackItemClick"
      />
    </m-modal>

    <entry-pack-add-confirm-modal
      :visible="confirmModalVisible"
      :entry-types="entryTypes"
      :entry-pack="currentEntryPack"
      :disabled="currentEntryPack?.amount > 1"
      @cancel="closeConfirmModal"
      @confirm="onConfirm"
    >
      <template #entryPack>
        <entry-pack-entry-type-row
          v-if="currentEntryPack"
          :entry-type="currentEntryPack"
          @onAmountChange="currentEntryPack.setGigaAmount($event, entryTypes)"
        >
          <template #price>
            <span />
          </template>

          <template #sum>
            <span />
          </template>
        </entry-pack-entry-type-row>
      </template>
    </entry-pack-add-confirm-modal>

    <m-button
      v-tooltip="t('entryPacks.addTooltip')"
      :disabled="disabled"
      class="entry-packs-modal__button"
      icon="pack"
      @click="openModal"
    />
  </div>
</template>

<script lang="ts">
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import EntryPacksTree from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksTree.vue'
import { MEntryPackPresenter } from '@/_api/MEntryPackPresenter/MEntryPackPresenter'
import { entryPacksEntryTypesAdapter } from '@/vue_apps/catalogs_root/EntryPacks/api/entryPacksEntryTypesAdapter'
import EntryPackAddConfirmModal
  from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksModal/EntryPackAddConfirmModal.vue'

import { EntryPackEntryType } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntryType'
import { EntryPackGigaEntryType } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackGigaEntryType'
import EntryPackEntryTypeRow
  from '@/vue_apps/catalogs_root/EntryPacks/components/EntryPacksModal/EntryPackEntryTypeRow.vue'

// @ts-ignore
import { cloneDeep } from 'lodash'

/**
 * Добавление энтрипака:
 * 1) клик в дереве (onEntryPackClick)
 * 2) открытие модалки подверждения (openConfirmModal)
 * 3) клик по добавить в модалке подтверждения
 * 4) подтверждение (onConfirm)
 */
export default {
  name: 'EntryPacksModal',
  components: {
    EntryPackEntryTypeRow,
    EntryPackAddConfirmModal,
    EntryPacksTree,
    MModal,
    MButton,
  },

  emits: ['appendEntryTypes'],

  data () {
    return {
      modalVisible: false,
      confirmModalVisible: false,

      entryTypes: [] as EntryPackEntryType[],
      currentEntryPack: null as EntryPackGigaEntryType,
    }
  },

  computed: {
    disabled () {
      return {
        disabled: !this.$security.canViewEntryPacksPack,
        tooltip: t('insufficient_access_rights'),
      }
    },
  },

  methods: {
    closeModal () {
      this.modalVisible = false
    },

    openModal () {
      this.modalVisible = true
    },

    openConfirmModal () {
      this.confirmModalVisible = true
    },

    closeConfirmModal () {
      this.confirmModalVisible = false
      this.entryTypes = []
      this.currentEntryPack = null
    },

    onEntryPackItemClick ({ id, title }) {
      this.currentEntryPack = new EntryPackGigaEntryType({ id, title })
    },

    async onEntryPackClick (id) {
      const config = {
        data: {
          showAllowedClinics: true,
          forCurrentClinic: true,
        },
        toClientAdapter: entryPacksEntryTypesAdapter.toClient,
      }

      /**
       * @type {*|{errors: Record<string, string[]>}}
       * @return IEntryPackEntryType[]
       */
      const entryTypes = await new MEntryPackPresenter().fetch(id, config)
      if (entryTypes.errors) { return }

      this.entryTypes = entryTypes.map((entryType) => new EntryPackEntryType(entryType))

      this.openConfirmModal()
    },

    /**
     * @param {IEntryPackEntryType[]} entryTypes
     */
    onConfirm (entryTypes) {
      this.$emit('appendEntryTypes', cloneDeep(entryTypes))
      this.closeConfirmModal()
      this.closeModal()
    },
  },
}
</script>
