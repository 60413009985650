import formatter from '@/lib/formatters/formatter'

export const referralSearchAdapter = {
  toClient (response) {
    return (response || []).map((item) => ({
      ...item,
      fullName: formatter.formatValue(item, ['user:fullName']),
      shortName: formatter.formatValue(item, ['user:shortName']),
    }))
  },
}
