<template>
  <div class="entry-types-tree-modal">
    <m-modal
      :visible.sync="modalVisibility"
      append-to-body
      width="min(70vw, 1200px)"
      :dialog-title="t('add_from_catalog')"
      use-max-height
    >
      <reusable-lazy-tree
        class="flex-grow-1"
        :tree="tree"
        :render-function="entryTypesTreeRenderContent"
        @onNodeClick="onNodeClick"
      />
    </m-modal>

    <m-button
      :disabled="!canViewEntryType"
      :tooltip="t('add_from_catalog')"
      icon="list"
      @click="modalVisibility = true"
    />
  </div>
</template>

<script>
import MModal from '@/vue_present/_base/MModal/MModal.vue'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import ReusableLazyTree from '@/vue_present/Reuse/LazyTree/ReusableLazyTree.vue'
import { EntryTypesCategoriesApi } from '@/vue_present/Reuse/EntryTypes/EntryTypesTreeModal/api/EntryTypesCategoriesApi'
import { entryTypesTreeRenderContent } from '@/vue_present/Reuse/EntryTypes/shared/entryTypesTreeRenderContent'
import { GLOBAL_CATEGORY_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  name: 'EntryTypesTreeModal',
  components: { ReusableLazyTree, MButton, MModal },

  props: {
    forCurrentClinic: { type: Boolean, default: true },
    forCurrentUser: { type: Boolean, default: true },
  },

  emits: ['onNodeClick'],

  data () {
    return {
      modalVisibility: false,
      tree: new EntryTypesCategoriesApi({
        categoryType: GLOBAL_CATEGORY_KINDS.ENTRY_TYPE,
        kind: false,
        forCurrentClinic: this.forCurrentClinic,
        forCurrentUser: this.forCurrentUser || undefined,
      }, {
        extraAttributes: ['teethMap'],
      }),
    }
  },

  computed: {
    canViewEntryType () {
      return this.$security.canViewEntryType
    },
  },

  methods: {
    entryTypesTreeRenderContent,

    onNodeClick (node) {
      if (node.children) { return }
      this.$emit('onNodeClick', {
        id: +node.id.replace(/\D/g, ''), /* приходит в формате: item:${id} */
        title: node.title,
        kind: node.kind,
        price: node.price,
        teethMap: node.extraAttributes?.teethMap || false,
        _shortMode: true,
      })
    },
  },
}
</script>
