import { omit } from 'lodash'
import { camelToSnake } from '@/_api/_requests/helpers'

export const referralAdapter = {
  toClient (response) {
    return response
  },
  toServer (data) {
    return JSON.stringify(camelToSnake({
      referral: {
        ...omit(data, ['address', 'widgetReferralLink', 'userId', 'medicalUser', 'company', 'specialties', 'phone']),
        phone: Services.phoneMask.removeMask(data.phone),
        addressAttributes: data.address,
        medicalUserId: data.medicalUser?.id || null,
        companyId: data.company?.id || null,
        specialtyIds: data.specialties?.length ? data.specialties.map(({ id }) => id) : [],
      },
    }))
  },
}
