<template>
  <div
    class="node-content flex align-center overflow-hidden"
    @click="onNodeContentClick"
  >
    <div class="mr-5">
      <m-icon
        v-if="data.children"
        :icon="node.expanded ? 'folderOpen' : 'folder'"
        color="warning"
      />
      <m-icon
        v-else
        icon="pack"
        color="gray"
      />
    </div>

    <div class="mr-5">
      <m-icon
        v-if="editable"
        class="node-content__action-button"
        icon="menu"
        color="gray"
        @click.stop="$emit('onContextClick', $event)"
      />
    </div>

    <div
      v-tooltip="data.title"
      class="node-content__title cut"
    >
      <span>{{ data.title }}</span>
    </div>
  </div>
</template>

<script>
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'

export default {
  name: 'EntryPacksTreeNode',
  components: { MIcon },
  props: {
    node: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },

    editable: Boolean,
  },

  emits: ['onContextClick'],

  methods: {
    onNodeContentClick ($event) {
      if (this.data?.id !== PSEUDO_ROOT_NODE_ID) { return }
      if (!this.node.expanded) { return }
      $event.preventDefault()
      $event.stopPropagation()
    },
  },
}
</script>
