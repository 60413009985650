import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'

export class CategoriesEntryTypesPresenter extends MPresenterBase {
  constructor (fetchNodeConfig) {
    super({
      entity: 'entryType',
      location: 'CategoriesEntryTypesPresenter',
      routes: {
        all () {},
        one () {},
        list () {},
      },
    })

    this.fetchNodeConfig = fetchNodeConfig
  }

  /**
   * Для LazyTree (ReusableLazyTree.vue)
   * @param id
   */
  fetchNode (id) {
    return this.post({
      url: Routes.entry_types_categories_path(),
      data: camelToSnake({
        id,
        ...this.fetchNodeConfig,
      }),
    }).promise
  }
}
