<template>
  <si-table
    v-if="items && items.length"
    v-loading="loading"
    class="si-table-generated"
    :class="{
      'si-table-generated_hide-scrollbars': hideScrollbars
    }"
  >
    <template
      v-if="!hideHeaders"
      #header
    >
      <slot name="table-header">
        <si-field>
          <div
            v-if="useCheckboxes"
            style="max-width: 30px"
          >
            <el-checkbox
              v-model="massSelectCheckBoxValue"
              class="el-checkbox_big"
              :indeterminate="massSelectIndeterminate"
            />
          </div>

          <slot
            v-for="tableField in tableFields"
            :name="`th-${tableField}`"
          >
            <div
              :key="tableField"
              class="col"
              :class="[`col__${tableField}`, tableStructure.headers[tableField].css, { 'col_full': !cutHeaders }]"
            >
              {{ tableStructure.headers[tableField].title }}
            </div>
          </slot>

          <slot name="table-header-options">
            <div
              v-if="!options.empty"
              class="col col__options"
              style="max-width: 80px"
            />
          </slot>
        </si-field>
      </slot>
    </template>

    <template #body>
      <slot name="table-body">
        <si-field
          v-for="item in items"
          :key="`itemId:${item.id}`"
          :deleted="item.deleted"
          :class="{ active: item.id === activeItemId }"
          class="pointer"
          @safe-click="!item.deleted && item.id !== activeItemId && $emit('onItemClick', item)"
          @restore="restoreItem(item)"
        >
          <div
            v-if="useCheckboxes"
            style="max-width: 30px"
          >
            <el-checkbox
              class="el-checkbox_big"
              :value="item.selected"
              :disabled="!item.selectable"
              @change="item.selected = $event; $emit('onSetItemSelected', item)"
            />
          </div>

          <slot
            v-for="tableField in tableFields"
            :name="`tr-${tableField}`"
            :item="item"
          >
            <div
              :key="tableField"
              class="col"
              :class="[`col__${tableField}`, tableStructure.headers[tableField].css]"
            >
              {{ item[tableField] }}
            </div>
          </slot>

          <div
            v-if="!options.empty"
            class="col col__options"
            style="max-width: 80px"
            @click.stop="() => {}"
          >
            <i
              v-if="options.edit"
              class="fad fa-pencil warning font-size-17 pointer ml-5"
              @click="$emit('onEditItemClick', item)"
            />

            <popover
              v-if="options.delete"
              @yes="deleteItem(item)"
            >
              <i
                slot="reference"
                class="fad fa-trash danger font-size-17 pointer ml-5"
              />
            </popover>
          </div>
        </si-field>
      </slot>
    </template>

    <template #footer>
      <slot name="table-footer" />
    </template>
  </si-table>
  <not-found-result
    v-else
    :filtered="isFiltered"
    :small="useNotFoundResultSmall"
    @reset-filters="$emit('resetFilters')"
  />
</template>

<script>
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import Popover from '@/vue_components/common/popover/popover'
import SiField from '@/vue_components/sort_items/si_table/si_field'
import SiTable from '@/vue_components/sort_items/si_table/si_table'
import { cloneDeep } from 'lodash'
import NotFoundResult from '@/vue_components/common/not_found_result'

export default {
  name: 'SiTableGenerator',
  components: { NotFoundResult, SiTable, SiField, Popover },
  props: {
    useCheckboxes: Boolean,
    tableSchema: PropsTypes.Object(),
    primaryIcon: PropsTypes.String(),
    items: PropsTypes.Array(),
    activeItemId: PropsTypes.Custom([String, Number], -1),
    loading: Boolean,
    isFiltered: Boolean,

    cutHeaders: PropsTypes.Boolean(true),
    hideHeaders: Boolean,
    hideScrollbars: Boolean,
    useNotFoundResultSmall: Boolean,
  },

  emits: [
    'onItemClick', // param: item from items
    'onSetItemSelected', // param: item from items
    'onEditItemClick', // param: item from items
    'onDeleteItem', // param: item from items
    'onRestoreItem', // param: item from items
    'onSetSelectedFields', // param: this.selectedFields

    'resetFilters', // not-found-result фича
  ],

  data () {
    return {
      selectableCount: 0,
      massSelectCheckBoxValue: false,
    }
  },

  computed: {
    tableStructure () {
      const tableStructure = cloneDeep(this.tableSchema)
      Object.keys(tableStructure.headers).forEach((key) => {
        if (typeof tableStructure.headers[key] !== 'string') { return }
        tableStructure.headers[key] = { title: tableStructure.headers[key] }
      })

      return tableStructure
    },

    tableFields () {
      return Object.keys(this.tableStructure.headers)
    },

    options () {
      return this.tableStructure.options || { empty: true }
    },

    selectedFields () {
      return this.useCheckboxes
        ? this.items.filter((item) => item.selected)
        : []
    },

    selectedCount () {
      return this.selectedFields.length
    },

    massSelectIndeterminate () {
      return this.selectedCount > 0 && this.selectedCount < this.selectableCount
    },
  },

  watch: {
    items () {
      this.calculateSelectableCount()
    },

    massSelectCheckBoxValue (to) {
      this.setSelectAllFields(to)
    },

    selectedFields () {
      this.$emit('onSetSelectedFields', this.selectedFields)
    },
  },

  created () {
    this.calculateSelectableCount()
  },

  methods: {
    calculateSelectableCount () {
      if (!this.useCheckboxes) { return }
      this.selectableCount = this.items.reduce((acc, item) => acc + Number(item.selectable), 0)
    },

    setSelectAllFields (value) {
      if (!this.useCheckboxes) { return }

      this.items.forEach((item) => {
        if (!item.selectable) { return }
        item.selected = value
      })
    },

    restoreItem (item) {
      this.$emit('onRestoreItem', item)
    },

    deleteItem (item) {
      this.$emit('onDeleteItem', item)
    },
  },
}
</script>
