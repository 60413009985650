<template>
  <div class="referral-editor__main-info flex flex-column pt-6">
    <!-- Фамилия -->
    <m-input
      v-model="tSurname"
      :label="t('surname')"
      required
      :disabled="disabled"
      validator-name="surname"
      @registerValidator="$registerValidator($event)"
    />

    <!-- Имя -->
    <m-input
      v-model="tName"
      :label="t('name')"
      :disabled="disabled"
    />

    <!-- Отчество -->
    <m-input
      v-model="tSecondName"
      :label="t('second_name')"
      :disabled="disabled"
    />

    <!-- Телефон -->
    <m-phone-input
      v-model="tPhone"
      :label="t('phone')"
      :disabled="disabled"
      disable-hidden-mask
      validator-name="phone"
      @registerValidator="$registerValidator($event)"
    />

    <!-- Email -->
    <m-input
      v-model="tEmail"
      :label="t('email')"
      :disabled="disabled"
      validator-name="email"
      @registerValidator="$registerValidator($event)"
    />

    <!-- Организация -->
    <company-search-create
      :value.sync="tCompany"
      :disabled="disabled"
      @onCreateCompany="tCompany = $event"
    />

    <!-- Специальность -->
    <m-select-lazy
      v-model="tSpecialties"
      :fetch-method="(params) => MSpecialtyPresenter.list(params)"
      :search-method="(params) => MSpecialtyPresenter.search(params)"
      multiple
      filterable
      full-width
      use-first-time-fetch
      :disabled="disabled"
      :label="t('specialty')"
    />

    <!-- Медпредставитель -->
    <m-select-lazy
      v-model="tMedicalUser"
      :fetch-method="fetchUsers"
      :search-method="searchUsers"
      :label="t('medical_user')"
      :disabled="!$security.canManageReferral"
      option-label="fullName"
      filterable
      full-width
      use-user-mode
      use-first-time-fetch
    />

    <!-- Код -->
    <m-input
      v-model="tCodeString"
      :disabled="disabled"
      :label="t('code')"
    />

    <!-- Реферальная ссылка -->
    <m-input
      v-if="widgetReferralLink && isClientWidgetEnabled"
      :value="widgetReferralLink"
      :label="t('client_widget.referral_link')"
      :clearable="false"
    />

    <!-- Описание -->
    <m-textarea
      v-model="tAbout"
      :label="t('about')"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MPhoneInput from '@/vue_present/_base/MPhoneInput/MPhoneInput.vue'
import MSelectLazy from '@/vue_present/_base/MSelectLazy/MSelectLazy.vue'
import MTextarea from '@/vue_present/_base/inputs/MTextarea/MTextarea.vue'
import CompanySearchCreate
  from '@/vue_apps/ClientsModule/components/Company/CompanySearchCreate/CompanySearchCreate.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { localBinding } from '@/vue_present/_helpers/localBinding'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'

export default {
  name: 'ReferralEditorMainInfo',
  components: { CompanySearchCreate, MTextarea, MSelectLazy, MPhoneInput, MInput },
  props: {
    id: PropsTypes.Number(),
    surname: PropsTypes.String(),
    name: PropsTypes.String(),
    secondName: PropsTypes.String(),
    phone: PropsTypes.String(),
    email: PropsTypes.String(),
    codeString: PropsTypes.String(),
    about: PropsTypes.String(),
    widgetReferralLink: PropsTypes.String(),
    userId: PropsTypes.Number(),

    company: PropsTypes.Object(),
    specialties: PropsTypes.Array([]),
    medicalUser: PropsTypes.Object(),

    specialtiesList: PropsTypes.Array([]),
  },

  data () {
    return {
      fetchUsers: (params) => doctorPresenter.fetchUsers(params),
      searchUsers: (params) => doctorPresenter.fetchUsers(params),
      MSpecialtyPresenter: new MSpecialtyPresenter(),
    }
  },

  computed: {
    tSurname: localBinding('surname'),
    tName: localBinding('name'),
    tSecondName: localBinding('secondName'),
    tPhone: localBinding('phone'),
    tEmail: localBinding('email'),
    tCodeString: localBinding('codeString'),
    tAbout: localBinding('about'),

    tCompany: localBinding('company'),
    tSpecialties: localBinding('specialties'),
    tMedicalUser: localBinding('medicalUser'),

    disabled () {
      return Boolean(this.userId) || !this.$security.canManageReferral
    },

    isClientWidgetEnabled () {
      return gon.application.client_widget_enabled
    },
  },
}
</script>
