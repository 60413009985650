import { ServiceRateItem } from '@/vue_present/ServiceRate/entities/ServiceRateItem'

export class ServiceRateCategory extends ServiceRateItem {
  constructor (item) {
    super(item)
    this.id = `categoryId:${item.id}`
    this.childNodes = []
  }

  static getIdWithPrefix (id) {
    return `categoryId:${id}`
  }

  setChildNodes (childNodes = []) {
    this.childNodes = childNodes
  }

  setRate (rate, rateType) {
    super.setRate(rate, rateType)
    this.childNodes.forEach((childNode) => { childNode.setRate(rate, rateType) })
  }

  unsetChildRate () {
    this.unsetRate()
    this.childNodes.forEach((childNode) => { childNode.unsetChildRate() })
  }

  unsetChildServiceRateId () {
    this.setServiceRateId()
    this.childNodes.forEach((childNode) => { childNode.unsetChildServiceRateId() })
  }
}
