import { GLOBAL_CATEGORY_ROOT_ID } from '@/_global_scripts/GLOBAL_CONSTS'
import { ServiceRateEntryType } from '@/vue_present/ServiceRate/entities/ServiceRateEntryType'
import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'

export const serviceRateLogicMixin = {
  data () {
    return {
      /** @duplicate */
      treeItemsFlatMap: { },
    }
  },

  methods: {
    /**
     * Заполнение плоской мапы категориями и энтритайпами для быстрого поиска
     * @param {ServiceRateCategory} node
     * @param {[categories: array, entryTypes: array]} categoriesResponse
     * @return {Array<ServiceRateEntryType | ServiceRateCategory>}
     */
    __fillTreeItemsFlatMap (node, categoriesResponse) {
      const nodeId = node?.id || GLOBAL_CATEGORY_ROOT_ID
      const [categories, entryTypes] = categoriesResponse

      /** @param {ServiceRateCategory | ServiceRateEntryType} item */
      const treeItemsFlatMap = (item) => {
        this.treeItemsFlatMap[item.id] = item
        this.treeItemsFlatMap[item.id].setParentNode(nodeId ? node : this.treeItemsFlatMap[GLOBAL_CATEGORY_ROOT_ID])
        this.treeItemsFlatMap[item.id].setRate(
          this.treeItemsFlatMap[item.id].rate ?? this.treeItemsFlatMap[nodeId].rate,
          this.treeItemsFlatMap[item.id].rateType ?? this.treeItemsFlatMap[nodeId].rateType
        )
      }

      categories.forEach(treeItemsFlatMap)
      entryTypes.forEach(treeItemsFlatMap)

      this.treeItemsFlatMap[nodeId].setChildNodes([
        ...categories,
        ...entryTypes,
      ])

      return [...categories, ...entryTypes]
    },

    /**
     * Сброс значений serviceRateId у узлов
     * @param {ServiceRateCategory | ServiceRateEntryType} currentNode
     * @param {Array} serviceCategories - список категорий с бэка
     * @param {Array} services - список энтритайпов с бэка
     * @param { Boolean } withParent - если true, то serviceRateId сбрасывается и у родителей
     * @private
     */
    __resetServiceRateIds (currentNode, serviceCategories = [], services = [], withParent = false) {
      if (withParent) { currentNode.unsetParentServiceRateId() }

      currentNode.unsetChildServiceRateId()

      const treeFM = this.treeItemsFlatMap
      serviceCategories.forEach(({ id, categoryId }) => {
        treeFM[ServiceRateCategory.getIdWithPrefix(categoryId)].setServiceRateId(id)
      })

      services.forEach(({ id, entryTypeId }) => {
        treeFM[ServiceRateEntryType.getIdWithPrefix(entryTypeId)].setServiceRateId(id)
      })
    },
  },
}
