import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'
import { ServiceRateEntryType } from '@/vue_present/ServiceRate/entities/ServiceRateEntryType'
import { camelToSnake } from '@/_api/_requests/helpers'
import { getEntitiesWhenUpdate, getEntityNodeType } from '@/_api/MEntityService/_helpers'

const ENTRY_TYPE_ID = 'entryTypeId'
const CATEGORY_ID = 'categoryId'

export const entityServiceAdapter = {
  toClient ({ categories = [], entryTypes = [], entityCategories = [], entityServices = [] }) {
    const _categories = categories.map((category) => new ServiceRateCategory(category))
    const _entryTypes = entryTypes.map((entryType) => new ServiceRateEntryType(entryType))

    entityCategories.forEach((entityCategory) => {
      const category = _categories.find((category) => category.getId() === entityCategory.categoryId)
      if (!category) { return }
      category.setRate(entityCategory.rate, entityCategory.rateType)
      category.setServiceRateId(entityCategory.id)
    })

    entityServices.forEach((entityService) => {
      const entryType = _entryTypes.find((entryType) => entryType.getId() === entityService.entryTypeId)
      if (!entryType) { return }
      entryType.setRate(entityService.rate, entityService.rateType)
      entryType.setServiceRateId(entityService.id)
    })

    return [
      _categories,
      _entryTypes,
    ]
  },

  /**
   * @param {string} entityName
   * @param {ServiceRateCategory[] | ServiceRateEntryType[]} nodes
   * @param {number} entityId
   * @return {[]}
   */
  toServer (entityName, nodes = [], entityId) {
    const { mappedNodes, services, serviceCategories } = getEntityNodeType(entityName)

    nodes.forEach((node) => {
      const nodeType = node.leaf ? services : serviceCategories
      const nodeKey = node.leaf ? ENTRY_TYPE_ID : CATEGORY_ID

      mappedNodes[nodeType].push({
        [`${entityName}Id`]: entityId,
        [nodeKey]: node.id,
        rate: node.rate,
        rateType: node.rateType,
        id: node.serviceRateId || undefined,
        forceClearDescendents: node._forceClearDescendents || undefined,
        _destroy: node._destroy || undefined,
      })
    })

    return camelToSnake(mappedNodes)
  },

  update (entityName, data) {
    const updateMap = getEntitiesWhenUpdate(entityName)

    return {
      services: data[updateMap.services],
      serviceCategories: data[updateMap.serviceCategories],
    }
  },
}
