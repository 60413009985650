import { IListCompositor } from '@/_api/_requests/MListServiceApi/compositors/IListCompositor'
import { IList } from '@/_declarations/IList'
import { MListServiceApi } from '@/_api/_requests/MListServiceApi/MListServiceApi'
import { IMListServiceApiConfig } from '@/_declarations/IMListServiceConfigApi'
import { IClientAdapter, IServerAdapter } from '@/_api/decorators/api/interfaces/IAdapter'
import { Callback } from '@/_api/decorators/callback'
import { Api } from '@/_api/decorators/api/Api'
import { ICRUDReturn } from '@/_api/decorators/api/interfaces/ICRUDReturn'

export class MCommonListCompositor<DataItem, FiltersMap> implements IListCompositor<DataItem, FiltersMap> {
  composition: MListServiceApi<DataItem, FiltersMap>

  config: IMListServiceApiConfig<DataItem, FiltersMap>

  toServer: IServerAdapter

  toClient: IClientAdapter

  src: string

  constructor (
    composition: MListServiceApi<DataItem, FiltersMap>,
    config: IMListServiceApiConfig<DataItem, FiltersMap>
  ) {
    this.composition = composition
    this.config = config
    this.src = config.src
    this.toServer = config?.adapter?.toServer || ((data: unknown) => data) as IServerAdapter
    this.toClient = config?.adapter?.toClient || ((data: unknown) => data) as IClientAdapter
  }

  @Callback('fillData')
  @Api.clientAdapter('toClient')
  @Api.list('src')
  @Api.serverAdapter('toServer')
  fetchAll (payload: Record<string, any>): Promise<void> {
    return { payload } as ICRUDReturn as Promise<void>
  }

  fillData (response: IList<DataItem[], DataItem>) {
    this.composition
      .setData(response.data.map((item) => new this.config.DataItemEntity(item)))

    if (response.totals && this.config.TotalsItemEntity) {
      this.composition.setTotals(new this.config.TotalsItemEntity(response.totals))
    }

    this.composition.setTotalItems(response.totalItems)
    this.composition.setTotalPages(response.totalPages)

    if (!this.composition.searchQuery) {
      this.composition.getCache().save(this.composition.filters)
    }

    this.composition.stopLoading()
  }
}
