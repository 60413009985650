<template>
  <div
    class="entry-pack-entry-type"
    :class="{
      'destroyed': removed
    }"
  >
    <m-icon
      :icon="icon"
      color="gray"
      class="entry-pack-entry-type__kind"
    />

    <div class="flex-grow-1 cut entry-pack-entry-type__title">
      {{ entryType.title }}
    </div>

    <div class="entry-pack-entry-type__price">
      <slot name="price">
        {{ price }}
      </slot>
    </div>

    <div class="entry-pack-entry-type__amount">
      <m-input
        type="number"
        :value="entryType.amount"
        :precision="0"
        :min="1"
        :m-fixed-height="false"
        :disabled="disabled || removed"
        controls
        @input="!disabled && $emit('onAmountChange', $event)"
      />
    </div>

    <div class="entry-pack-entry-type__sum">
      <slot name="sum">
        {{ sum }}
      </slot>
    </div>

    <div class="entry-pack-entry-type__remove">
      <slot
        v-if="entryType.isRemovable"
        name="remove"
      >
        <m-icon
          v-if="entryType.removed"
          v-tooltip="t('restore')"
          class="pointer"
          icon="send"
          color="primary"
          @click="$emit('restore')"
        />
        <m-icon
          v-else
          v-tooltip="t('delete')"
          class="pointer"
          icon="delete"
          color="danger"
          @click="$emit('remove')"
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import { EntryPackEntryType } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntryType'

export default {
  name: 'EntryPackEntryTypeRow',
  components: { MInput, MIcon },

  props: {
    entryType: { type: EntryPackEntryType, required: true },
    disabled: Boolean,
  },

  emits: [
    'onAmountChange',
    'remove',
    'restore',
  ],

  computed: {
    removed () {
      return this.entryType.isRemovable && this.entryType.removed
    },

    icon () {
      return this.$filters.entryTypeIcon(this.entryType.kind) || 'pack'
    },

    price () {
      return this.$filters.price(this.entryType.price)
    },

    sum () {
      return this.$filters.price(this.entryType.price * this.entryType.amount)
    },
  },
}
</script>
