
export const entryPacksAdapter = {
  /**  @param {EntryPack} data */
  toServer (data) {
    return {
      name: data.name,
      comment: data.comment,
      entriesAttributes: data.entries.items
        .filter((entry) => !(!entry.entryPackEntryId && entry.destroyed))
        .map((entry) => ({
          id: entry.entryPackEntryId,
          entryTypeId: entry.entryTypeId,
          entryPackId: data.id,
          amount: entry.amount,
          _destroy: entry.destroyed,
        })),
    }
  },
}
