<template>
  <div class="service-rate-tree flex flex-column">
    <el-tree
      class="pb-15"
      :node-key="treeMeta.nodeKey"
      :props="treeMeta.props"
      :load="loadNode"
      :render-content="treeMeta.servicesRatesTreeRenderContent"
      :indent="25"
      :empty-text="treeMeta.emptyText"
      lazy
    />

    <service-rate-popover
      :visible="popoverMeta.visible"
      :x="popoverMeta.x"
      :y="popoverMeta.y"
      :popover-title="popoverMeta.title"
      :rate-value.sync="popoverMeta.rate"
      :rate-type-value.sync="popoverMeta.rateType"
      @close="popoverMeta.visible = false"
      @setRate="setRate(currentNode, popoverMeta.rate, popoverMeta.rateType)"
      @resetRate="setRate(currentNode)"
    />
  </div>
</template>

<script>
import { GLOBAL_CATEGORY_ROOT_ID, GLOBAL_CURRENCY_TYPES } from '@/_global_scripts/GLOBAL_CONSTS'
import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'
import { servicesRatesTreeRenderContent } from '@/vue_present/ServiceRate/helpers/servicesRatesTreeRenderContent'
import { serviceRateLogicMixin } from '@/vue_present/ServiceRate/helpers/serviceRateLogicMixin'
import ServiceRatePopover from '@/vue_present/ServiceRate/components/ServiceRatePopover.vue'
import { confirmCategoryChangingDialog } from '@/vue_present/ServiceRate/helpers/confirmCategoryChangingDialog'

export default {
  name: 'ServiceRate',
  components: { ServiceRatePopover },
  mixins: [
    serviceRateLogicMixin,
  ],

  props: {
    entityId: { type: Number, required: true },
    fetchCategory: { type: Function, required: true },
    updateNode: { type: Function, required: true },
  },

  data () {
    return {
      treeMeta: {
        props: {
          label: 'title',
          isLeaf: 'leaf',
          children: 'childrenNodes',
        },
        nodeKey: 'id',
        emptyText: t('loading_data'),

        servicesRatesTreeRenderContent: (h, data) => servicesRatesTreeRenderContent.call(this, h, data),
      },

      popoverMeta: {
        visible: false,
        rate: 0,
        rateType: GLOBAL_CURRENCY_TYPES.PERCENT,
        title: null,
        x: 0,
        y: 0,
      },

      /**
       * @type {Record<number, ServiceRateCategory | ServiceRateEntryType>} treeItemsFlatMap
       */
      treeItemsFlatMap: {
        [GLOBAL_CATEGORY_ROOT_ID]:
            new ServiceRateCategory({ id: GLOBAL_CATEGORY_ROOT_ID, rate: null, rateType: null }),
      },

      /**
       * @type {ServiceRateEntryType | ServiceRateCategory} currentNode
       */
      currentNode: null,
    }
  },

  methods: {
    /**
     * Включение/выключение оказываемой услуги чекбоксом
     * @param {ServiceRateCategory | ServiceRateEntryType} serviceRateItem
     * @return {Promise<void>}
     */
    async onServiceItemCBClick (serviceRateItem) {
      if (!await confirmCategoryChangingDialog(serviceRateItem)) { return }

      serviceRateItem.noRate()
        ? await this.onSetRateValue(serviceRateItem, 0, GLOBAL_CURRENCY_TYPES.PERCENT)
        : await this.onResetRateValue(serviceRateItem)

      this.afterProcessing()
    },

    /**
     * Установка/удаление значения у услуги
     * @param {ServiceRateCategory | ServiceRateEntryType} serviceRateItem
     * @param {Number?} rate
     * @param {("percent" | "fixed")?} rateType
     * @return {Promise<void>}
     */
    async setRate (serviceRateItem, rate, rateType) {
      if (!await confirmCategoryChangingDialog(serviceRateItem)) { return }
      rate === undefined || rateType === undefined
        ? await this.onResetRateValue(serviceRateItem)
        : await this.onSetRateValue(serviceRateItem, rate, rateType)

      this.afterProcessing()
    },

    /**
     * Обработка клика по Rate
     * @param {MouseEvent} e
     * @param {ServiceRateCategory | ServiceRateEntryType} data
     */
    onRateClick ({ e, data }) {
      this.popoverMeta.x = e.clientX
      this.popoverMeta.y = e.clientY
      this.popoverMeta.title = data.title
      this.popoverMeta.rate = Utils.toMoney(data.rate) || 0
      this.popoverMeta.rateType = data.rateType || GLOBAL_CURRENCY_TYPES.PERCENT

      this.currentNode = data

      this.popoverMeta.visible = true
    },

    afterProcessing () {
      this.popoverMeta.visible = false
      this.popoverMeta.title = ''
      this.popoverMeta.rate = 0
      this.popoverMeta.rateType = GLOBAL_CURRENCY_TYPES.PERCENT
    },

    /**
     * Загрузка узла
     * @param {ServiceRateCategory} data
     * @param {Function} resolve - колбак от el-tree
     * @return {Promise<void>}
     */
    async loadNode ({ data }, resolve) {
      const nodeId = data?.getId() || GLOBAL_CATEGORY_ROOT_ID
      const categoriesResponse = await this.fetchCategory(this.entityId, nodeId)
      if (categoriesResponse?.errors) { return }

      const mappedItems = this.__fillTreeItemsFlatMap(data, categoriesResponse)
      resolve(mappedItems)
    },

    /**
     * Установка нового значения узла
     * @param {ServiceRateCategory | ServiceRateEntryType} currentNode
     * @param {number} rate
     * @param {"percent" | "fixed"} rateType
     * @return {Promise<void>}
     */
    async onSetRateValue (currentNode, rate, rateType) {
      const rawNode = currentNode.generateSingleQueryData(rate, rateType)
      const { serviceRateId } = rawNode

      this.treeItemsFlatMap[rawNode.rawId].loadingStart()
      const { errors, serviceCategories, services } =
          await this.updateNode(this.entityId, [rawNode])

      if (errors) { return }

      this.__resetServiceRateIds(currentNode, serviceCategories, services)

      /**
       * поменялась концепция обновления
       * бекенд больше не отправляет назад service,
       * который был обновлён сменой rate или rateType,
       * поэтому this.__resetServiceRateIds::currentNode.unsetChildServiceRateId() работает несколько иначе
       */
      if (serviceRateId) {
        currentNode.setServiceRateId(serviceRateId)
      }

      currentNode.setRate(rate, rateType)

      this.treeItemsFlatMap[rawNode.rawId].loadingStop()
    },

    /**
     * Сброс значения узла
     * @param {ServiceRateCategory | ServiceRateEntryType} currentNode
     * @return {Promise<void>}
     */
    async onResetRateValue (currentNode) {
      const rawNodes = currentNode.generateQueryData()

      rawNodes.forEach((node) => this.treeItemsFlatMap[node.rawId].loadingStart())
      const { errors, serviceCategories, services } =
          await this.updateNode(this.entityId, rawNodes)

      if (errors) { return }

      this.__resetServiceRateIds(currentNode, serviceCategories, services, true)

      currentNode.unsetRate()
      currentNode.unsetParentRate()
      currentNode.unsetChildRate()

      rawNodes.forEach((node) => this.treeItemsFlatMap[node.rawId].loadingStop())
    },
  },
}
</script>
