import { ServiceRateItem } from '@/vue_present/ServiceRate/entities/ServiceRateItem'
import { entryTypesKindsIcons } from '@/modules/utils/medods_ui/entryTypesKindsIcons'

export class ServiceRateEntryType extends ServiceRateItem {
  constructor (item) {
    super(item)
    this.id = `entryTypeId:${item.id}`
    this.kind = item.kind
    this.icon = entryTypesKindsIcons[item.kind]
    this.leaf = true
  }

  static getIdWithPrefix (id) {
    return `entryTypeId:${id}`
  }
}
