/**
 * @implements {IFlatDepthTreeNode}
 */
export class FlatDepthTreeNode {
  /** @type {FlatDepthTree} */
  tree

  /** @type {number} */
  id

  /** @type {string} */
  title

  /** @type {boolean} */
  isLeaf

  /** @type {FlatDepthTreeNode} */
  parent = null

  /** @type {FlatDepthTreeNode[]} */
  children = []

  selected = false

  /**
   * @param {FlatDepthTree} tree
   * @param {TFlatDepthTreeNodeRaw} rawNode
   * @param {FlatDepthTreeNode} parent
   */
  constructor (tree, rawNode, parent = null) {
    this.tree = tree

    this.id = rawNode.id
    this.title = rawNode.title || ''
    this.isLeaf = !rawNode.folder
    this.parent = parent

    if (!rawNode.folder) { this.children = null }
  }

  renameNode (newTitle = '') {
    this.title = newTitle
    this.sort('current')
  }

  /** @param {TFlatDepthTreeNodeRaw} rawNode */
  addNode (rawNode) {
    const newNode = new FlatDepthTreeNode(this.tree, rawNode, this)
    this.children.push(newNode)
    this.sort('current')

    return newNode
  }

  deleteNode () {
    this.parent.deleteChildNode(this)
    this.parent = null
    this.tree._deleteNodeFromCache(this)
  }

  /** @param {FlatDepthTreeNode} node */
  deleteChildNode (node) {
    this.children = this.children.filter((child) => child.id !== node.id)
  }

  /** @param {'deep' | 'current'} sortKind */
  sort (sortKind = 'deep') {
    if (!this.children) { return }

    this.children.sort(
      (a, b) =>
        a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1
    )

    this.children = [
      ...this.children.filter((node) => !node.isLeaf),
      ...this.children.filter((node) => node.isLeaf),
    ]

    if (sortKind === 'deep') {
      this.children.forEach((node) => { node.sort() })
    }
  }

  select (selected = false) {
    this.selected = selected

    selected
      ? this.tree.treeSelectedNodes.add(this.id)
      : this.tree.treeSelectedNodes.delete(this.id)

    if (!this.children) { return }
    this.children.forEach((child) => {
      child.select(selected)
    })
  }
}
