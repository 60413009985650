import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MEntryPackPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'entryPack',
      location: 'MEntryPackPresenter',
      routes: {
        all: Routes.api_internal_entry_packs_packs_path,
        one: Routes.api_internal_entry_packs_pack_path,
        list: Routes.list_api_internal_entry_packs_packs_path,
      },
    })
  }
}
