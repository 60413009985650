import { TCallback } from '@/_api/decorators/interfaces/TCallback'
import { extractArray } from '@/helpers/extractArray'

export function Callback (callback: TCallback | string, catchCallback: string = '') {
  return function<This, Args extends any[], Return> (
    target: (this: This, ...args: Args) => Return,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context: ClassMethodDecoratorContext<This, (this: This, ...args: Args) => Return>
  ) {
    return function (this: This, ...args: Args) {
      const result = target.call(this, ...args)

      const isClassMember = typeof callback === 'string'
      const callable: TCallback = isClassMember
        ? this[callback as string]
        : callback

      /**
       * Синхронный метод
       */
      if (!(result instanceof Promise)) {
        return callable.call(this, ...extractArray(result))
      }

      /**
       * Асинхронный метод
       */
      const promise = result
        .then((args: string[]) => callable.call(this, ...extractArray(args)) as Promise<Awaited<Return>>)

      if (!catchCallback) { return promise }

      return promise
        .catch((args: string[]) => callable.call(this, ...extractArray(args))) as Promise<Awaited<Return>>
    }
  }
}
