import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { entityServiceAdapter } from '@/_api/MEntityService/entityServiceAdapter'
import { getRoutes } from '@/_api/MEntityService/_helpers'

export class MEntityServicePresenter extends MPresenterBase {
  constructor (entityName = '') {
    if (!entityName) {
      throw new Error('Не указана сущность')
    }

    const routes = getRoutes(entityName)
    if (!routes) {
      throw new Error('Не найдены маршруты')
    }

    super({
      entity: 'servicesCategory',
      location: 'MEntityServicePresenter',
      routes: { one: routes.fetchCategory, all () {} },
    })

    this.entityName = entityName
    this.routes = routes
  }

  fetchCategory (entityId, categoryId = 0) {
    const options = {
      url: this.routes.fetchCategory(),
      data: camelToSnake({ entityId, categoryId }),
    }

    const notification = new MRequestNotification(
      'MEntityServicePresenter::fetchCategory',
      'fetchMessage',
      'servicesCategory'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse(entityServiceAdapter.toClient))
      .catch(MRequestError.onRequest(notification))
  }

  update (entityId, nodes) {
    const options = {
      url: this.routes.update(entityId),
      data: { [this.entityName]: entityServiceAdapter.toServer(this.entityName, nodes, entityId) },
    }

    const notification = new MRequestNotification(
      'MEntityServicePresenter::update',
      'updateMessage',
      'servicesCategory'
    )

    const toClientAdapter = (data) => entityServiceAdapter.update(this.entityName, snakeToCamel(data))

    return this.patch(options, toClientAdapter).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.onRequest(notification))
  }
}
