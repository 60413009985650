<template>
  <div class="m-search-select-input-deprecated">
    <el-dropdown
      class="m-dropdown"
      :trigger="triggerType"
      :hide-on-click="!useStopHideOnClick"
      placement="bottom-start"
      @visible-change="$emit('onDropdownVisibleChange', $event)"
      @menu-item-click="$emit('onMenuItemClick', $event)"
    >
      <m-input
        :value="searchQuery"
        :placeholder="placeholder"
        :disabled="disabled"
        :label="label"
        @input="updateSearchQuery"
        @change="updateSearchQuery"
        @clear="clearSelect"
      />
      <el-dropdown-menu
        class="dropdown_m-search-select-input"
        :class="dropDownMenuClass"
      >
        <div class="search-results">
          <slot>
            <el-dropdown-item
              v-for="item in items"
              :key="`dropdownItem:${item[valueKey]}`"
              class="m-dropdown__item"
              :command="item"
            >
              <m-icon
                v-if="item.icon"
                :icon="item.icon"
                class="mr-5 m-dropdown__icon"
                :class="item.iconCss"
              />

              <span class="m-dropdown__item-text">{{ item[optionLabel] }}</span>
            </el-dropdown-item>
          </slot>
        </div>

        <el-dropdown-item
          v-if="loading"
          disabled
        >
          {{ t('loading_1') }}
        </el-dropdown-item>

        <div
          v-if="searchQuery && searchQuery.length >= minLength && notResults"
          class="no-search-results"
        >
          <el-dropdown-item disabled>
            {{ t('nothing_has_found') }}
          </el-dropdown-item>
        </div>

        <div
          v-if="!allowSearch"
          class="hint is-disabled"
        >
          <min-string-length-hint
            v-if="(searchQuery && searchQuery.length < minLength) || !searchQuery"
            :value="searchQuery"
            :min-length="minLength"
          />
        </div>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MinStringLengthHint from '@/vue_components/common/min_string_length_hint.vue'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import MIcon from '@/vue_present/_base/MIcon/MIcon.vue'
import { GLOBAL_SEARCH_MIN_LENGTH } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  components: { MIcon, MinStringLengthHint, MInput },

  props: {
    triggerType: PropsTypes.String('hover'),

    items: PropsTypes.Array(),

    valueKey: PropsTypes.String('id'),

    optionLabel: PropsTypes.String('title'),

    label: { type: String, default: '' },

    searchQuery: PropsTypes.String(),

    notResults: Boolean,

    placeholder: PropsTypes.String(),

    allowSearch: Boolean,
    loading: Boolean,
    disabled: Boolean,

    minLength: PropsTypes.Number(GLOBAL_SEARCH_MIN_LENGTH),

    useStopHideOnClick: Boolean,

    dropDownMenuClass: PropsTypes.Custom([String, Array, Object]),
  },

  emits: [
    'onMenuItemClick',
    'update:searchQuery',
    'clearSelect',
    'onDropdownVisibleChange',
  ],

  methods: {
    updateSearchQuery (searchQuery) {
      this.$emit('update:searchQuery', searchQuery)
    },

    clearSelect () {
      this.$emit('update:searchQuery', '')
      this.$emit('clearSelect')
    },
  },
}
</script>
