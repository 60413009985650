const USER = 'user'
const REFERRAL = 'referral'

/**
 * @param {string} entityName
 * @return {{fetchCategory: Function, update: Function}|null}
 */
export const getRoutes = (entityName) => {
  switch (entityName) {
    case USER: return {
      fetchCategory: Routes.user_services_query_path,
      update: Routes.services_user_path,
    }

    case REFERRAL: return {
      fetchCategory: Routes.referral_services_query_path,
      update: Routes.referral_services_path,
    }

    default: return null
  }
}

/**
 * @param entityName
 * @return {{services: string, serviceCategories: string, mappedNodes: {referralServicesAttributes: *[], referralServiceCategoriesAttributes: *[]}}|null|{services: string, serviceCategories: string, mappedNodes: {userServiceCategoriesAttributes: *[], userServicesAttributes: *[]}}}
 */
export const getEntityNodeType = (entityName) => {
  switch (entityName) {
    case USER: return {
      mappedNodes: {
        userServicesAttributes: [],
        userServiceCategoriesAttributes: [],
      },
      services: 'userServicesAttributes',
      serviceCategories: 'userServiceCategoriesAttributes',
    }

    case REFERRAL: return {
      mappedNodes: {
        referralServicesAttributes: [],
        referralServiceCategoriesAttributes: [],
      },
      services: 'referralServicesAttributes',
      serviceCategories: 'referralServiceCategoriesAttributes',
    }

    default: return null
  }
}

/**
 * @param entityName
 * @return {{services: string, serviceCategories: string}|null}
 */
export const getEntitiesWhenUpdate = (entityName) => {
  switch (entityName) {
    case USER: return {
      serviceCategories: 'userServiceCategories',
      services: 'userServices',
    }

    case REFERRAL: return {
      serviceCategories: 'referralServiceCategories',
      services: 'referralServices',
    }

    default: return null
  }
}
