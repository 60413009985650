import { attractionSourcesEndpoint } from '@/api_entities/catalogs/attraction_sources/attraction_sources_endpoint'
import { reportErrorText, reportSuccessText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { camelToSnake } from '@/_api/_requests/helpers'

export const attractionSourcesPresenter = {
  fetchAll (sortParams = {}) {
    const data = {
      ...sortParams,
      title: sortParams.searchQuery,
    }

    return attractionSourcesEndpoint.fetchAll(camelToSnake(data))
      .catch(Utils.reportError(
        'responseErrors:fetchAll()',
        reportErrorText('list_message', 'attraction_sources')
      ))
  },

  submit (data) {
    const errorMessage = data.id
      ? 'update_message'
      : 'create_message'

    return attractionSourcesEndpoint.submit(data)
      .then((response) => {
        Utils.reportSuccess(
          reportSuccessText(errorMessage, 'attraction_source')
        )()

        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => {
        return Promise.reject(err?.responseJSON || {})
      })
  },

  destroy (id, replacementSourceId) {
    return attractionSourcesEndpoint.destroy(id, replacementSourceId)
      .then((response) => {
        Utils.reportSuccess(
          reportSuccessText('delete_message', 'attraction_source')
        )()

        return new Promise((resolve) => resolve(response))
      })
      .catch((err) => {
        Utils.reportError(
          'responseErrors:destroy()',
          reportErrorText('delete_message', 'attraction_source')
        )(err)
        throw new Error(err)
      })
  },
}
