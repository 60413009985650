import { ServiceRateCategory } from '@/vue_present/ServiceRate/entities/ServiceRateCategory'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'

export const confirmCategoryChangingDialog = async (serviceRateItem) => {
  if (serviceRateItem instanceof ServiceRateCategory) {
    const confirmResult = await MConfirm.warning(t('confirm_node_change'))
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false))
    if (!confirmResult) { return }
  }

  return true
}
