import { EntryPackEntryType } from '@/vue_apps/catalogs_root/EntryPacks/entities/EntryPackEntryType'

export class EntryPackGigaEntryType extends EntryPackEntryType {
  isRemovable = false

  setGigaAmount (amount: number, entryTypes: EntryPackEntryType[]) {
    super.setAmount(amount)

    entryTypes.forEach((entryType) => {
      entryType.setAmount(entryType.defaultAmount * amount, false)
    })
  }
}
