<template>
  <div class="flex flex-column">
    <!-- Поиск -->
    <m-suggestion-select
      type="address"
      :placeholder="t('search_by_address')"
      :disabled="disabled"
      full-width
      @change="onSuggestionReplace"
    />

    <!-- Индекс -->
    <m-input
      :value="index"
      :label="t('index')"
      :disabled="disabled"
      @input="$updateSyncGC('index', $event)"
    />

    <!-- Страна -->
    <m-input
      :value="country"
      :label="t('country')"
      :disabled="disabled"
      @input="$updateSyncGC('country', $event)"
    />

    <!-- Регион -->
    <reusable-global-n-s-i-list
      v-if="useNSIRussianSubjectsDictionary"
      :value="nsiRussianSubjectId"
      :placeholder="t('region')"
      :label="t('region')"
      :disabled="disabled"
      full-width
      m-fixed-height
      dictionary="russian_subjects"
      @change="$updateSyncGC('nsiRussianSubjectId', $event)"
      @changeItem="$updateSyncGC('region', $event && $event.title)"
    />
    <m-input
      v-else
      :value="region"
      :label="t('region')"
      :disabled="disabled"
      @input="$updateSyncGC('region', $event)"
    />

    <!-- Район -->
    <m-input
      :value="area"
      :label="t('area')"
      :disabled="disabled"
      @input="$updateSyncGC('area', $event)"
    />

    <!-- Город -->
    <m-input
      :value="city"
      :label="t('city')"
      :disabled="disabled"
      @input="$updateSyncGC('city', $event)"
    />

    <!-- Улица -->
    <m-input
      :value="street"
      :label="t('street')"
      :disabled="disabled"
      @input="$updateSyncGC('street', $event)"
    />

    <!-- Дом -->
    <m-input
      :value="house"
      :label="t('house')"
      :disabled="disabled"
      @input="$updateSyncGC('house', $event)"
    />

    <!-- Квартира -->
    <m-input
      :value="flat"
      :label="t('flat')"
      class="pb-0"
      :disabled="disabled"
      @input="$updateSyncGC('flat', $event)"
    />
  </div>
</template>

<script>
import MInput from '@/vue_present/_base/inputs/MInput/MInput.vue'
import MSuggestionSelect from '@/vue_present/_base/MSuggestionSelect/MSuggestionSelect.vue'
import { extractAddressFromSuggestionAddress } from '@/vue_present/Reuse/CommonAddressForm/helpers'
import ReusableGlobalNSIList from '@/vue_present/Reuse/Lists/NSI/ReusableGlobalNSIList.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CommonAddressForm',
  components: { ReusableGlobalNSIList, MSuggestionSelect, MInput },
  props: {
    disabled: Boolean,

    index: { type: String, default: null },
    country: { type: String, default: null },
    region: { type: String, default: null },
    area: { type: String, default: null },
    city: { type: String, default: null },
    street: { type: String, default: null },
    house: { type: String, default: null },
    flat: { type: String, default: null },
    nsiRussianSubjectId: { type: Number, default: null },
  },

  computed: {
    ...mapGetters('globalCatalogs/nsiStore/russianSubjectsDictionaryStore', {
      getRussianSubject: 'vxGetRussianSubjectMethod',
    }),

    useNSIRussianSubjectsDictionary () {
      return gon.application.nsi.russianSubjectsDictionary
    },
  },

  methods: {
    onSuggestionReplace (address) {
      const newAddress = extractAddressFromSuggestionAddress(address)
      newAddress.nsiRussianSubjectId = this.getRussianSubject(address.region)?.id || null

      this.$emit('suggestionUpdate', newAddress)
    },
  },
}
</script>
