import { request } from '@/lib/transport/request'
import { attractionSourcesAdapter } from './attraction_sources_adapter'
import { HTTP_METHODS } from '@/lib/transport/MRequest'

export const attractionSourcesEndpoint = {
  fetchAll (sort_params) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.catalogs_attraction_sources_list_path(),
      data: {
        sort_params,
      },
    }

    return request(options).promise
  },

  get (id) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.attraction_source_path(id),
    }

    return request(options)
  },

  submit (data) {
    return data.id
      ? attractionSourcesEndpoint.update(data)
      : attractionSourcesEndpoint.create(data)
  },

  update (data) {
    const options = {
      type: HTTP_METHODS.PATCH,
      url: Routes.attraction_source_path(data.id),
      data: attractionSourcesAdapter.toServer(data),
    }

    return request(options).promise
  },

  create (data) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.attraction_sources_path(),
      data: attractionSourcesAdapter.toServer(data),
    }

    return request(options).promise
  },

  destroy (id, replacementSourceId) {
    const options = {
      type: HTTP_METHODS.DELETE,
      url: Routes.attraction_source_path(id, {
        replacement_id: replacementSourceId,
      }),
    }

    return request(options).promise
  },
}
