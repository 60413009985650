<template>
  <div class="catalog-tree-node-actions-container flex gap-indent-small">
    <m-button
      v-if="isCatalog"
      v-tooltip="t('categoriesActions.addCategoryTooltip')"
      icon="folder"
      icon-color="warning"
      @click.stop="onAddFolderClick"
    />

    <m-button
      v-if="isCatalog"
      v-tooltip="t('categoriesActions.addItemTooltip')"
      icon="filePlus"
      icon-color="success"
      @click.stop="onAddItemClick"
    />

    <m-button
      v-if="canChanged"
      v-tooltip="t('categoriesActions.editTooltip')"
      icon="edit"
      icon-color="warning"
      @click.stop="onEditClick"
    />

    <m-button
      v-if="canChanged"
      v-tooltip="t('categoriesActions.deleteTooltip')"
      icon="delete"
      icon-color="danger"
      @click.stop="onDeleteClick"
    />

    <m-button
      template="close"
      @click="$emit('clear')"
    />
  </div>
</template>

<script>
import { FlatDepthTreeNode } from '@/vue_present/Reuse/CatalogTree/store/forest/FlatDepthTree/FlatDepthTreeNode'
import MButton from '@/vue_present/_base/buttons/MButton/MButton.vue'
import { MConfirm } from '@/vue_present/_base/MConfirm/MConfirm'
import { PSEUDO_ROOT_NODE_ID } from '@/vue_present/Reuse/LazyTree/const'

export default {
  name: 'CatalogTreeNodeActions',
  components: { MButton },
  props: {
    node: { type: [FlatDepthTreeNode, Object], default: null },
  },

  emits: [
    'onEdit',
    'onAdd',
    'onDelete',
    'clear',
  ],

  computed: {
    isCatalog () {
      return !this.node.isLeaf
    },

    canChanged () {
      return this.node.parent &&
          (this.node.id !== PSEUDO_ROOT_NODE_ID)
    },
  },

  methods: {
    async __promptCreate (isCatalog = false) {
      const title = isCatalog
        ? t('categoriesActions.newFolder')
        : t('categoriesActions.newItem')

      return this.$prompt(title, '', {
        confirmButtonText: t('categoriesActions.addItem'),
        confirmButtonClass: 'm-button is-plain el-button--success',
        cancelButtonClass: 'm-button is-plain',
        inputPattern: /.+/,
        inputErrorMessage: t('errors.filled?'),
      })
        .then(({ value }) => ({ value }))
        .catch(() => ({ errors: true }))
    },

    async __promptEdit (inputValue) {
      return this.$prompt(t('categoriesActions.edit'), '', {
        confirmButtonText: t('categoriesActions.confirm'),
        confirmButtonClass: 'm-button is-plain el-button--warning',
        cancelButtonClass: 'm-button is-plain',
        inputValue,
        inputPattern: /.+/,
        inputErrorMessage: t('errors.filled?'),
      })
        .then(({ value }) => ({ value }))
        .catch(() => ({ errors: true }))
    },

    async onAddFolderClick () {
      const { errors, value: name } = await this.__promptCreate(true)
      if (errors) { return }
      this.$emit('onAdd', { parentNode: this.node, name, isCategory: true })
    },

    async onAddItemClick () {
      const { errors, value: name } = await this.__promptCreate()
      if (errors) { return }
      this.$emit('onAdd', { parentNode: this.node, name, isCategory: false })
    },

    async onEditClick () {
      const { errors, value: name } = await this.__promptEdit(this.node.title)
      if (errors) { return }
      this.$emit('onEdit', { node: this.node, name })
    },

    async onDeleteClick () {
      const result = await MConfirm.error(t('delete_1'), { title: '' })
      if (result !== 'confirm') { return }
      this.$emit('onDelete', this.node)
    },
  },
}
</script>
