import { entryPacksAdapter } from '@/vue_apps/catalogs_root/EntryPacks/api/entryPacksAdapter'
import { MEntryPackPresenter } from '@/_api/MEntryPackPresenter/MEntryPackPresenter'
import { extractItemId } from '@/vue_apps/catalogs_root/EntryPacks/const/extractItemId'
import { entryPackListAdapter } from '@/vue_apps/catalogs_root/EntryPacks/api/entryPackListAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'

/**
 * @implements ILazyTreeApiPresenter
 */
export class EntryPackPresenter extends MEntryPackPresenter {
  constructor () {
    super()
    this.location = 'EntryPackPresenter'
  }

  /**
   * @param {number} id
   * @param {Object} config
   * @returns { Promise<IEntryPackRaw | { errors: Record<string, string[]> }> }
   */
  async fetch (id, config = {}) {
    return super.fetch(id, config)
  }

  async fetchNode (id, categoryType, config = {}) {
    return this.post({
      url: Routes.entry_packs_api_internal_categories_path(),
      data: camelToSnake({
        id,
        ...config.data,
      }),
    })
      .promise
  }

  async submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: (data) => ({ ...entryPacksAdapter.toServer(data) }),
      toJson: true,
    })
  }

  async update (updateData, config = {}) {
    const id = extractItemId(updateData.id)

    return super.update({ ...updateData, id }, config)
  }

  destroy (id, config = {}) {
    return super.destroy(extractItemId(id), config)
  }

  /**
   * @param entryTypeId
   * @return {Promise<*>}
   */
  async fetchAllowedClinics (entryTypeId) {
    return this.post({
      url: Routes.fetch_allowed_clinics_api_internal_entry_types_path(),
      data: { entry_type: { id: entryTypeId } },
    }).promise
      .catch(() => Promise.resolve([]))
  }

  async list (listData, config = {}) {
    return super.list(listData, {
      toServerAdapter: entryPackListAdapter.toServer,
      toJson: true,
    })
  }
}
