<template>
  <div
    v-loading="loading"
    class="referral-editor h-100"
  >
    <el-tabs
      v-model="currentTab"
      class="referral-editor__tabs"
    >
      <!-- Основные поля -->
      <el-tab-pane
        :label="t('mainInfo')"
        name="mainInfo"
      >
        <referral-editor-main-info
          v-bind.sync="tempReferral"
          @registerValidator="onRegisterValidator"
        />
      </el-tab-pane>

      <!-- Адрес -->
      <el-tab-pane :label="t('address')">
        <common-address-form
          v-bind="tempReferral.address"
          class="mt-6 mb-indent-validation"
          :disabled="disabled"
          @updateSyncGC="tempReferral.address[$event.prop] = $event.value"
          @suggestionUpdate="tempReferral.address = $event"
        />
      </el-tab-pane>

      <!-- Услуги -->
      <el-tab-pane
        v-if="isReferralExists"
        :label="t('services')"
        lazy
        name="services"
      >
        <service-rate
          v-if="serviceTreeReRender"
          :update-node="servicesUpdateNode"
          :fetch-category="servicesFetchCategory"
          :entity-id="id"
        />
      </el-tab-pane>
    </el-tabs>

    <div
      v-if="!currentTabServices"
      class="referral-editor__buttons flex justify-content-between align-items-center"
    >
      <m-button-submit
        v-if="$security.canManageReferral"
        @click="onSubmit"
      />

      <m-button-delete
        v-if="$security.canManageReferral && tempReferral.id"
        @yes="onDestroy"
      />
    </div>
  </div>
</template>

<script>
import ReferralEditorMainInfo
  from '@/vue_apps/catalogs_root/Referrals/components/ReferralEditor/ReferralEditorMainInfo.vue'
import CommonAddressForm from '@/vue_present/Reuse/CommonAddressForm/CommonAddressForm.vue'
import { Referral } from '@/vue_apps/catalogs_root/Referrals/entities/Referral'
import { ValidationParentMixin } from '@/vue_present/mixins/ValidationParentMixin'
import { PropsTypes } from '@/vue_present/_base/PropsTypes'
import { MReferralPresenter } from '@/_api/MReferral/MReferralPresenter'
import { SpinnerHolder } from '@/vue_components/mixins/spinner_holder'
import MButtonDelete from '@/vue_present/_base/buttons/MButtonDelete/MButtonDelete.vue'
import MButtonSubmit from '@/vue_present/_base/buttons/MButtonSubmit/MButtonSubmit.vue'
import { clinicPresenter } from '@/api_entities/clinics/clinicPresenter'
import { GLOBAL_DEFAULT_ADDRESS } from '@/_global_scripts/GLOBAL_CONSTS'
import ServiceRate from '@/vue_present/ServiceRate/ServiceRate.vue'
import { MEntityServicePresenter } from '@/_api/MEntityService/MEntityServicePresenter'
import { DEFAULT_TAB, SERVICES_TAB } from '@/vue_apps/catalogs_root/Referrals/components/const'

const servicePresenter = new MEntityServicePresenter('referral')

export default {
  name: 'ReferralEditor',
  components: { ServiceRate, MButtonSubmit, MButtonDelete, CommonAddressForm, ReferralEditorMainInfo },
  mixins: [ValidationParentMixin, SpinnerHolder],

  props: {
    id: PropsTypes.Number(),
  },

  data () {
    return {
      tempReferral: new Referral(),
      clinicAddress: null,

      servicesFetchCategory: (entityId, categoryId = 0) => servicePresenter.fetchCategory(entityId, categoryId),
      servicesUpdateNode: (entityId, node) => servicePresenter.update(entityId, node),
      serviceTreeReRender: true,

      currentTab: DEFAULT_TAB,
    }
  },

  computed: {
    disabled () {
      return Boolean(this.tempReferral?.userId) || !this.$security.canManageReferral
    },

    isReferralExists () {
      return Boolean(this.id)
    },

    currentTabServices () {
      return this.currentTab === SERVICES_TAB
    },
  },

  watch: {
    id (to) {
      if (to === null) { return this.onNewReferral() }
      if (!to) { return this.resetReferral() }
      this.fetchReferral(to)
      this.reRenderServicesTree()
    },
  },

  created () {
    if (!this.id) { return this.onNewReferral() }
    this.fetchReferral(this.id)
  },

  methods: {
    /**
     * БЛ: при создании нового реферала заполняются поля адреса
     * @returns {Promise<void>}
     */
    async onNewReferral () {
      this.currentTab = DEFAULT_TAB

      if (!this.clinicAddress) {
        const address = await clinicPresenter.fetchClinicAddress(gon.application.current_clinic.id)
        if (address?.errors) { return }
        this.clinicAddress = address
      }

      this.resetReferral({
        address: {
          ...GLOBAL_DEFAULT_ADDRESS,
          country: this.clinicAddress.country,
          region: this.clinicAddress.region,
          city: this.clinicAddress.city,
        },
      })
    },

    resetReferral (referral) {
      this.tempReferral = new Referral(referral)
    },

    async fetchReferral (id) {
      const referral = await this.withSpinner(new MReferralPresenter().fetch(id))
      if (referral?.errors) { return }
      this.tempReferral = new Referral(referral)
    },

    async onSubmit () {
      if (this.hasErrors()) { return }

      const referral = await this.withSpinner(new MReferralPresenter().submit(this.tempReferral))
      if (referral?.errors) {
        this.resetValidations(referral.errors)

        return
      }

      this.$emit('updateList')
      this.$emit('submitReferral', referral)

      if (this.tempReferral.id) {
        this.tempReferral.widgetReferralLink = referral.widgetReferralLink
        this.tempReferral.address = referral.address

        return
      }

      this.resetReferral()
    },

    async onDestroy () {
      const referral = await this.withSpinner(new MReferralPresenter().destroy(this.tempReferral.id))
      if (referral?.errors) { return }

      this.$emit('updateList')
      this.$emit('successDestroy')
      this.resetReferral()
    },

    reRenderServicesTree () {
      this.serviceTreeReRender = false
      this.$nextTick(() => {
        this.serviceTreeReRender = true
      })
    },
  },
}
</script>
