import { entryTypesKindsIcons } from '@/modules/utils/medods_ui/entryTypesKindsIcons'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'

export const entryTypesTreeRenderContent = (h, { node, data }) => {
  const iconClass = !data.isLeaf
    ? node.expanded
      ? 'fa-folder-open warning'
      : 'fa-folder warning'
    : entryTypesKindsIcons[data.kind]

  const hPrice = !data.isLeaf
    ? null
    : h('span', { class: 'entry-types-tree-node__price' }, vueFilters.currency(data.price))

  return h(
    'span',
    { class: 'entry-types-tree-node cut' },
    [
      h('i', { class: `fad fa-fw entry-types-tree-node__icon ${iconClass}`, style: 'min-width: 20px' }),
      h('span', { directives: [{ name: 'tooltip', value: data.title }], class: 'entry-types-tree-node__title cut' }, data.title),
      hPrice,
    ]
  )
}
