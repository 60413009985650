import { MPresenterBase } from '@/_api/_requests/MPresenterBase'

export class MCategoriesPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'category',
      location: 'MCategoriesPresenter',
      routes: {
        one: Routes.category_path,
        all: Routes.categories_path,
      },
    })
  }

  list (listData, config = {}) {
    throw new Error('404')
  }
}
