import { REQUEST_KEY } from './const'

export const attractionSourcesAdapter = {
  toServer (data) {
    return {
      [REQUEST_KEY]: {
        title: data.title,
        phone: data.phone,
      },
    }
  },
}
