<template>
  <div
    class="col-container catalog-container"
    :class="catalogClass"
  >
    <m-panel
      :icon="catalogIcon"
      :title="primaryTitle"
      class="catalog-container__list"
      :use-type-by-route="false"
    >
      <slot name="primary" />
    </m-panel>

    <m-panel
      v-if="isEditMode||isCreateMode"
      :icon="tableIcon"
      :title="tableTitle"
      :custom-class="customClassForm"
      class="catalog-container__editor"
      :type="headerType"
      use-close-button
      @close="$emit('close')"
    >
      <template #buttons>
        <span
          class="panel_close_button fad fa-fw fa-times"
          @click="$emit('close')"
        />
      </template>

      <slot name="form" />
    </m-panel>
  </div>
</template>

<script>
import { MODES } from '@/vue_apps/catalogs_root/_catalog_base/const/const'
import MPanel from '@/vue_present/_base/MPanel/MPanel.vue'
import { GLOBAL_DEFAULT_COLORS } from '@/_global_scripts/GLOBAL_CONSTS'

export default {
  name: 'CatalogsBase',
  components: {
    MPanel,
  },

  props: {
    catalogClass: {
      type: String,
      default: 'col-item-45',
    },

    catalogIcon: {
      type: String,
      default: '',
    },

    canManage: Boolean,

    catalogTitle: {
      type: String,
      default: '',
    },

    tableIcon: {
      type: String,
      default: '',
    },

    tableTitle: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: '',
    },

    customClassForm: {
      type: String,
      default: '',
    },
  },

  computed: {
    readOnlyText () {
      return !this.canManage
        ? ` (${t('read_only')})`
        : ''
    },

    primaryTitle () {
      return this.catalogTitle + this.readOnlyText
    },

    isEditMode () {
      return this.mode === MODES.EDIT
    },

    isCreateMode () {
      return this.mode === MODES.CREATE
    },

    headerType () {
      return this.isCreateMode
        ? GLOBAL_DEFAULT_COLORS.SUCCESS
        : GLOBAL_DEFAULT_COLORS.WARNING
    },
  },
}

</script>
