import { cloneDeep, parseInt } from 'lodash'
import { GLOBAL_REFERRAL_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'

export class Referral {
  /**
   * @param {TReferral} referral
   */
  constructor (referral = {}) {
    this.id = referral.id || null
    this.userId = referral.userId || null
    this.surname = referral.surname || ''
    this.name = referral.name || ''
    this.secondName = referral.secondName || ''
    this.phone = referral.phone || ''
    this.email = referral.email || ''
    this.codeString = referral.codeString || ''
    this.widgetReferralLink = referral.widgetReferralLink || ''
    this.about = referral.about || ''

    this.company = referral.company?.id ? referral.company : null

    this.specialties = referral.specialties || []

    this.medicalUser = referral.medicalUser?.id
      ? { id: parseInt(referral.medicalUser.id), fullName: referral.medicalUser.title }
      : null

    this.address = cloneDeep(referral.address) || {
      index: '',
      country: '',
      region: '',
      area: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      nsiRussianSubjectId: null,
    }

    this.kind = referral.kind || GLOBAL_REFERRAL_KINDS.EXTERNAL
  }
}
