import LazyTree from '@/vue_present/Reuse/LazyTree/store/LazyTree'
import {
  CategoriesEntryTypesPresenter,
} from '@/vue_present/Reuse/EntryTypes/EntryTypesTreeModal/api/CategoriesEntryTypesPresenter'

export class EntryTypesCategoriesApi extends LazyTree {
  constructor (fetchNodeConfig, config = {}) {
    super(new CategoriesEntryTypesPresenter(fetchNodeConfig), config)
  }
}
